import { FunctionComponent } from 'react'
import { Group } from '@mantine/core'

import { PlatformIcon } from 'src/components'

export interface PlatformLabelProps {
  platform: string
}

export const PlatformLabel: FunctionComponent<PlatformLabelProps> = props => {
  return (
    <Group align="center" spacing={0} style={{ display: 'inline-flex' }}>
      <PlatformIcon platform={props.platform} />
      &nbsp;{props.platform}
    </Group>
  )
}
