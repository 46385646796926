import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { Account, Organization } from 'src/types'

interface UserState {
  token: string | undefined
  profile: Account | undefined
  trackedPlatforms: string[] | undefined
  organization: Organization | undefined
}

const initialState = {
  token: undefined,
  profile: undefined,
  trackedPlatforms: undefined,
  organization: undefined,
} as UserState

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => initialState,
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    setAccount(state, action: PayloadAction<Account>) {
      state.profile = action.payload
    },
    setTrackedPlatforms(state, action: PayloadAction<string[]>) {
      state.trackedPlatforms = action.payload
    },
    setOrganization(state, action: PayloadAction<Organization>) {
      state.organization = action.payload
    },
  },
})

export const {
  logout,
  setAccount,
  setToken,
  setTrackedPlatforms,
  setOrganization,
} = userSlice.actions
export default userSlice.reducer
