import { useNavigate } from 'react-router'

import { Button, Card, Space, Text, Tooltip, Badge } from '@mantine/core'
import { DataGrid } from '@mui/x-data-grid'

import { theme } from 'src/styling/theme'
import { Empty } from 'src/components'
import { CommentAuthor } from 'src/types'

import {
  TROLL_FINDER_COLUMNS,
  EXPANDED_TROLL_FINDER_COLUMNS,
} from './TrollFinderColumns'

interface TrollFinderCardProps {
  expanded: boolean
  trolls: CommentAuthor[]
  setProfileExpanded?: (expanded: boolean) => void
  setExpandedCommentAuthor?: (commentAuthor: CommentAuthor) => void
}

export const TrollFinderCard = (props: TrollFinderCardProps) => {
  const navigate = useNavigate()

  const handleRowClick = (params: any) => {
    if (!props.setProfileExpanded || !props.setExpandedCommentAuthor) return
    props.setProfileExpanded(true)
    props.setExpandedCommentAuthor(params.row as CommentAuthor)
  }

  return (
    <Card shadow="xl" radius="lg" p="xl">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text
          size="xl"
          color={theme.colors.dark[2]}
          weight={500}
          style={{ marginRight: '10px' }}
        >
          Troll Finder
        </Text>
        {!props.expanded && (
          <Tooltip
            multiline
            label="The Troll Finder feature displays commenters that have posted multiple abusive comments on your social media accounts within the selected timeframe. Only the top 5 trolls are displayed on the Dashboard."
          >
            <Badge color="pink" size="md" radius="xs">
              BETA
            </Badge>
          </Tooltip>
        )}
      </div>

      <Space h="md" />

      {(!props.trolls || props.trolls.length === 0) && (
        <Empty
          title="Looks like your community is troll-free! 🎉"
          message={
            <>
              Try adjusting the timeframe to see if any past troublemakers pop
              up.
            </>
          }
        />
      )}

      {props.trolls && props.trolls.length > 0 && (
        <DataGrid
          autoHeight
          sx={{
            '& .MuiDataGrid-row:hover': {
              backgroundColor: props.expanded ? 'default' : 'inherit',
              cursor: props.expanded ? 'pointer' : 'default',
            },
          }}
          style={{
            border: 0,
            boxShadow: 'none',
            padding: 0,
          }}
          rows={props.trolls}
          columns={
            props.expanded
              ? EXPANDED_TROLL_FINDER_COLUMNS
              : TROLL_FINDER_COLUMNS
          }
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          getRowId={row =>
            `${row.platform}-${row.platformId}-${row.trackedUser.id}`
          }
          disableRowSelectionOnClick={props.expanded ? false : true}
          // hideFooter={props.expanded ? false : true}
          hideFooter={true}
          onRowClick={props.expanded ? handleRowClick : () => {}}
        />
      )}
      {!props.expanded && (
        <>
          <Space h="md" />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => navigate('/troll-fan-profiles?tab=trolls')}>
              See All Troll Activity
            </Button>
          </div>
        </>
      )}
    </Card>
  )
}
