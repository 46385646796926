import { Text } from '@mantine/core'

export const ErrorMessage = ({ error }: { error?: string | null }) => {
  if (!error) return null

  return (
    <Text
      style={{
        color: '#FF6738',
        textAlign: 'center',
        fontSize: '12px',
      }}
    >
      {error}
    </Text>
  )
}
