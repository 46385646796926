/**
 * Calculates the change in percentage between two values.
 *
 * @param newValue the newest value
 * @param previousValue the previous value
 * @returns the change percentage between the two values
 */
export const calculatePercentageChange = (
  newValue: number,
  previousValue: number
): number => {
  let change = ((newValue - previousValue) / previousValue) * 100
  if (change > -2 && change < 0) change = Math.abs(change) // for values between -2 and 0
  return Number.isFinite(change) ? Number(change.toFixed(1)) : 0
}
