import { useEffect } from 'react'

import { Button, Card, Title, Text, Group, Select, Stack } from '@mantine/core'

import { LanguageTypeLabels, Account } from 'src/types'
import { useFetchAccount, useSendUpdateAccount } from 'src/api'
import ResetAccountPassword from './ResetAccountPassword'
import { modals } from '@mantine/modals'

interface MyAccountProps {
  profile: Account
}

const openResetAccountPasswordModal = () => {
  modals.open({
    title: 'Reset Account Password',
    centered: true,
    children: <ResetAccountPassword />,
    styles: {
      content: {
        backgroundColor: '#232132 !important',
      },
      header: {
        backgroundColor: '#232132',
      },
      title: {
        backgroundColor: '#232132',
        color: 'white',
      },
      close: {
        svg: {
          color: 'white',
        },
      },
    },
  })
}

export const MyAccount = ({ profile }: MyAccountProps) => {
  const { fetch: fetchAccount } = useFetchAccount({
    showErrorNotification: true,
  })
  const { send: sendUpdateSettings } = useSendUpdateAccount()

  useEffect(() => {
    fetchAccount()
  }, [fetchAccount])

  return (
    <section
      className="page-section"
      style={{
        display: 'flex',
        flex: 1,
        marginBottom: 0,
        flexDirection: 'column',
      }}
    >
      <header>
        <Title order={2}>My Account</Title>
      </header>
      <Card style={{ overflow: 'visible', flex: 1 }}>
        <Stack spacing={10}>
          <Group position="apart">
            <Text>Name</Text>
            <Text>{profile?.name}</Text>
          </Group>
          <Group position="apart">
            <Text>Email</Text>
            <Text>{profile?.email}</Text>
          </Group>
        </Stack>
        <Group position="apart" mt={10}>
          <Text>Translation Language</Text>
          <Select
            data={Object.entries(LanguageTypeLabels).map(([key, value]) => ({
              label: value,
              value: key,
            }))}
            value={profile?.translationLanguage || 'en'}
            onChange={v => sendUpdateSettings(v as string)}
          />
        </Group>
        <Group position="apart" mt={10}>
          <Text>Password</Text>
          <Button
            onClick={() => {
              openResetAccountPasswordModal()
            }}
          >
            Reset
          </Button>
        </Group>
      </Card>
    </section>
  )
}
