import { FunctionComponent } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { createStyles, Tooltip } from '@mantine/core'
import { Icon } from '@iconify/react'

const useStyles = createStyles(theme => ({
  navLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 20,
    background: 'rgba(255, 255, 255, 0.16)',
    color: theme.colors.dark[0],
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      '& > .iconify': {
        width: 22,
        height: 22,
      },
    },
    '&.active': {
      backgroundColor: theme.colors.secondary[5],
      color: theme.colors.dark[6],
    },
  },
}))

export interface NavbarLinkProps extends Omit<NavLinkProps, 'children'> {
  label: string
  icon: string
}

export const NavbarLink: FunctionComponent<NavbarLinkProps> = props => {
  const { classes } = useStyles()
  return (
    <Tooltip label={props.label} position="right" offset={12}>
      <NavLink
        className={({ isActive }) =>
          `${classes.navLink} ${isActive ? 'active' : ''}`
        }
        {...props}
      >
        <Icon icon={props.icon} width={20} />
      </NavLink>
    </Tooltip>
  )
}
