import { notifications } from '@mantine/notifications'

import { setOrganization } from 'src/redux/reducers'
import { store } from 'src/redux/store'

import client from '../client'

interface UpdateProps {
  moderateSpam: boolean
  moderateAbuse: boolean
}

export const useSendUpdateOrganization = () => {
  const state = store.getState()
  const organization = state.user.organization
  const previousValueModerateSpam = organization?.moderateSpam || false
  const previousValueModerateAbuse = organization?.moderateAbuse || false

  const updateOrganizationState = (props: UpdateProps) => {
    const organization = state.user.organization

    if (organization) {
      store.dispatch(
        setOrganization({
          ...organization,
          moderateSpam: props.moderateSpam,
          moderateAbuse: props.moderateAbuse,
        })
      )
    }
  }

  const handleSuccess = () => {
    notifications.show({
      title: 'Moderation Settings Updated',
      message: 'Your settings have been updated successfully.',
      color: 'teal',
    })
  }

  const handleError = () => {
    notifications.show({
      title: 'Uh-oh! Something Went Wrong',
      message:
        'We were unable to complete this action. Please try again later.',
    })

    updateOrganizationState({
      moderateSpam: previousValueModerateSpam,
      moderateAbuse: previousValueModerateAbuse,
    })
  }

  const send = async (props: UpdateProps) => {
    updateOrganizationState(props)

    try {
      const response = await client.put('/accounts/organization', {
        moderate_spam: props.moderateSpam,
        moderate_abuse: props.moderateAbuse,
      })

      if (response.status === 204) {
        handleSuccess()
      }
    } catch (error: any) {
      handleError() // error.message
    }
  }

  return { send }
}
