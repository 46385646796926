import { useState, useCallback, useEffect } from 'react'

import { notifications } from '@mantine/notifications'

import { AccountResponse, Response } from 'src/types'
import { setAccount } from 'src/redux/reducers'
import { store } from 'src/redux/store'

import client from '../client'

interface FetchAccountProps {
  showErrorNotification?: boolean
}

export const useFetchAccount = ({
  showErrorNotification = false,
}: FetchAccountProps = {}) => {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const state = store.getState()

  const handleError = useCallback(
    (error: any) => {
      setError(error.message)

      if (showErrorNotification)
        notifications.show({
          title: 'Uh-oh! Something Went Wrong',
          message: error.message,
        })
    },
    [showErrorNotification]
  )

  const fetch = useCallback(async () => {
    try {
      const response = await client.get('/accounts/account')
      const data = response.data as Response<AccountResponse>
      store.dispatch(setAccount(data.response.user))
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  }, [handleError])

  useEffect(() => {
    if (!state.user.token || state.user.profile) return
    fetch()
  }, [state.user.token, state.user.profile, fetch])

  return { error, loading, fetch }
}
