import { useState } from 'react'

import { RegisterAccountRequestData } from 'src/types'

import client from '../client'

export const useSendRegisterAccount = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const send = async (data: RegisterAccountRequestData): Promise<boolean> => {
    let response
    setLoading(true)

    try {
      response = await client.post('/accounts/account', {
        name: data.name,
        email: data.email,
        password: data.password,
      })
    } catch (error: any) {
      console.log(error.response.data.response.error)
      setError(error.response.data.response.error)
    } finally {
      setLoading(false)
      return response?.status === 204
    }
  }

  return { loading, error, send }
}
