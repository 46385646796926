import {
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  GridRowParams,
  GridActionsCellItem,
} from '@mui/x-data-grid'
import { modals } from '@mantine/modals'
import { Icon } from '@iconify/react'

import {
  PlatformUsername,
  AbuseTags,
  CommentModal,
  ActionModal,
} from 'src/components'
import {
  TrackedUser,
  ModerationResolveTypeLabels,
  ActionLogEntry,
  ModerationResolveType,
  ModerationActionType,
} from 'src/types'
import { parseToLocalDateTime } from 'src/utils/date'
import { createCommenterProfileLink } from 'src/utils/socials'

const openActionModal = (
  title: string,
  action: ModerationActionType,
  id: number
) => {
  modals.open({
    title: title,
    centered: true,
    children: <ActionModal action={action} singleAction={true} singleId={id} />,
    size: 'md',
  })
}

const openCommentModal = (entry: ActionLogEntry) => {
  modals.open({
    title: 'Comment Details',
    centered: true,
    children: <CommentModal entry={entry} />,
    size: 'lg',
  })
}

const TRACKED_USER_COLUMN = {
  field: 'trackedUser',
  headerName: 'To'.toLocaleUpperCase(),
  flex: 1,
  sortable: false,
  renderCell: (params: GridRenderCellParams<TrackedUser>) => (
    <PlatformUsername
      platform={params.value.platform}
      username={params.value.platformUsername}
    />
  ),
}

const COMMENT_AUTHOR_COLUMN = {
  field: 'commentAuthor',
  headerName: 'From'.toLocaleUpperCase(),
  flex: 1,
}

const COMMENT_COLUMN = {
  field: 'comment',
  headerName: 'Comment'.toLocaleUpperCase(),
  flex: 3,
  sortable: false,
}

const ABUSE_TAGS_COLUMN = {
  field: 'abuseTags',
  headerName: 'Abuse Types'.toLocaleUpperCase(),
  flex: 1.25,
  sortable: false,
  renderCell: (params: GridRenderCellParams<string[]>) => {
    if (params.value) return <AbuseTags tags={params.value} numToShow={1} />
    return <div></div>
  },
}

const COMMENT_POSTED_COLUMN = {
  field: 'commentPosted',
  headerName: 'Posted'.toLocaleUpperCase(),
  flex: 1.2,
  valueGetter: (params: GridValueGetterParams) =>
    parseToLocalDateTime(params.row.commentPosted),
}

const RESOLVED_STATUS_COLUMN = {
  field: 'resolutionStatus',
  headerName: 'Status'.toLocaleUpperCase(),
  flex: 0.75,
  sortable: false,
  valueGetter: (params: GridValueGetterParams) =>
    `${ModerationResolveTypeLabels[params.value?.status]}`,
}

const UNRESOLVED_ACTIONS_COLUMN = {
  field: 'actions',
  type: 'actions',
  width: 60,
  getActions: (params: GridRowParams) => {
    const rowEntry: ActionLogEntry = params.row.entry as ActionLogEntry
    let actionItems = [
      <GridActionsCellItem
        icon={<Icon icon="mdi:arrow-expand" />}
        onClick={() => openCommentModal(rowEntry)}
        label="Expand"
      />,
      <GridActionsCellItem
        icon={<Icon icon="mdi:eye-off" />}
        onClick={() =>
          openActionModal(
            'Confirm: Hide',
            ModerationActionType.HIDE,
            rowEntry.id
          )
        }
        label="Hide"
        showInMenu
      />,
    ]

    if (rowEntry.trackedUser.platform !== 'TIKTOK')
      actionItems.push(
        <GridActionsCellItem
          icon={<Icon icon="mdi:delete" />}
          onClick={() =>
            openActionModal(
              'Confirm: Delete',
              ModerationActionType.DELETE,
              rowEntry.id
            )
          }
          label="Delete"
          showInMenu
        />
      )

    actionItems.push(
      <GridActionsCellItem
        icon={<Icon icon="mdi:shield-off" />}
        onClick={() =>
          openActionModal(
            'Confirm: Not Abusive',
            ModerationActionType.FALSE_POSITIVE,
            rowEntry.id
          )
        }
        label="Not Abusive"
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Icon icon="mdi:archive" />}
        onClick={() =>
          openActionModal(
            'Confirm: Archive',
            ModerationActionType.ARCHIVE,
            rowEntry.id
          )
        }
        label="Archive"
        showInMenu
      />
    )

    if (rowEntry.comment)
      actionItems.push(
        <GridActionsCellItem
          icon={<Icon icon="material-symbols:content-copy" />}
          onClick={() => navigator.clipboard.writeText(rowEntry.comment.text)}
          label="Copy Comment"
          showInMenu
        />
      )

    if (rowEntry.comment && rowEntry.comment.sourceUrl)
      actionItems.push(
        <GridActionsCellItem
          icon={<Icon icon="mdi:open-in-new" />}
          onClick={() => window.open(rowEntry.comment.sourceUrl)}
          label="View Original"
          showInMenu
        />
      )

    if (rowEntry.comment) {
      const platformURL = createCommenterProfileLink(
        rowEntry.trackedUser.platform,
        rowEntry.comment.author
      )

      if (platformURL !== '') {
        actionItems.push(
          <GridActionsCellItem
            icon={<Icon icon="mdi:identification-card-outline" />}
            onClick={() => window.open(platformURL)}
            label="View Profile"
            showInMenu
          />
        )
      }
    }

    return actionItems
  },
}

const RESOLVED_ACTIONS_COLUMN = {
  field: 'actions',
  type: 'actions',
  width: 60,
  getActions: (params: GridRowParams) => {
    const rowEntry: ActionLogEntry = params.row.entry as ActionLogEntry
    let actionItems = [
      <GridActionsCellItem
        icon={<Icon icon="mdi:arrow-expand" />}
        onClick={() => openCommentModal(rowEntry)}
        label="Expand"
      />,
    ]

    if (rowEntry.resolution.status === ModerationResolveType.HIDDEN) {
      actionItems.push(
        <GridActionsCellItem
          icon={<Icon icon="mdi:eye" />}
          onClick={() =>
            openActionModal(
              'Confirm: Undo Hide',
              ModerationActionType.UNDO_HIDE,
              rowEntry.id
            )
          }
          label="Undo Hide"
          showInMenu
        />
      )

      if (rowEntry.trackedUser.platform !== 'TIKTOK') {
        actionItems.push(
          <GridActionsCellItem
            icon={<Icon icon="mdi:delete" />}
            onClick={() =>
              openActionModal(
                'Confirm: Delete',
                ModerationActionType.DELETE,
                rowEntry.id
              )
            }
            label="Delete"
            showInMenu
          />
        )
      }
    }

    if (rowEntry.resolution.status === ModerationResolveType.ARCHIVED)
      actionItems.push(
        <GridActionsCellItem
          icon={<Icon icon="mdi:archive-remove" />}
          onClick={() =>
            openActionModal(
              'Confirm: Unarchive',
              ModerationActionType.UNARCHIVE,
              rowEntry.id
            )
          }
          label="Unarchive"
          showInMenu
        />
      )

    if (rowEntry.comment)
      actionItems.push(
        <GridActionsCellItem
          icon={<Icon icon="material-symbols:content-copy" />}
          onClick={() => navigator.clipboard.writeText(rowEntry.comment.text)}
          label="Copy Comment"
          showInMenu
        />
      )

    if (rowEntry.comment && rowEntry.comment.sourceUrl)
      actionItems.push(
        <GridActionsCellItem
          icon={<Icon icon="mdi:open-in-new" />}
          onClick={() => window.open(rowEntry.comment.sourceUrl)}
          label="View Original"
          showInMenu
        />
      )

    if (rowEntry.comment) {
      const platformURL = createCommenterProfileLink(
        rowEntry.trackedUser.platform,
        rowEntry.comment.author
      )

      if (platformURL !== '') {
        actionItems.push(
          <GridActionsCellItem
            icon={<Icon icon="mdi:identification-card-outline" />}
            onClick={() => window.open(platformURL)}
            label="View Profile"
            showInMenu
          />
        )
      }
    }

    return actionItems
  },
}

export const createColumnData = (isUnresolved: boolean) => {
  if (isUnresolved)
    return [
      TRACKED_USER_COLUMN,
      COMMENT_AUTHOR_COLUMN,
      COMMENT_COLUMN,
      ABUSE_TAGS_COLUMN,
      COMMENT_POSTED_COLUMN,
      UNRESOLVED_ACTIONS_COLUMN,
    ] as GridColDef[]

  return [
    TRACKED_USER_COLUMN,
    COMMENT_AUTHOR_COLUMN,
    COMMENT_COLUMN,
    ABUSE_TAGS_COLUMN,
    COMMENT_POSTED_COLUMN,
    RESOLVED_STATUS_COLUMN,
    RESOLVED_ACTIONS_COLUMN,
  ] as GridColDef[]
}
