import { Card, Title, Text, Group, Switch, Stack, Space } from '@mantine/core'

import { useSendUpdateOrganization } from 'src/api'

interface ModerationProps {
  moderateSpam: boolean
  moderateAbuse: boolean
  isCompany: boolean
}

export const ModerationSettings = (props: ModerationProps) => {
  const { send } = useSendUpdateOrganization()

  return (
    <section
      className="page-section"
      style={{
        display: 'flex',
        flex: 1,
        marginBottom: 0,
        flexDirection: 'column',
      }}
    >
      <header>
        <Title order={2}>Moderation Settings</Title>
      </header>
      <Card style={{ overflow: 'visible', flex: 1 }}>
        <Stack spacing={10}>
          <Group position="apart">
            <Text>Moderating Spam</Text>
            <Switch
              checked={props.moderateSpam}
              onChange={event => {
                send({
                  moderateSpam: event.currentTarget.checked,
                  moderateAbuse: props.moderateAbuse,
                })
              }}
            />
          </Group>
          <Group position="apart">
            <Text>Moderating Abuse</Text>
            <Switch
              checked={props.moderateAbuse}
              onChange={event => {
                send({
                  moderateSpam: props.moderateSpam,
                  moderateAbuse: event.currentTarget.checked,
                })
              }}
              disabled={!props.isCompany}
            />
          </Group>
          <Space h="46px" />
        </Stack>
      </Card>
    </section>
  )
}
