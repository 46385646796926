import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

import { PlatformLabel } from 'src/components'
import { PlatformTypeLabels, TrackedPlatformsStatsResponse } from 'src/types'
import { CountFormat, PercentFormat } from 'src/utils/constants'
import { calculatePercentageChange } from 'src/utils/formulas'
import { TrackedPlatformStats } from '~/types/models/stats/trackedPlatformStats'
import { formatRowDisplay, formatDifference } from 'src/utils/component'

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Platform'.toLocaleUpperCase(),
    sortable: false,
    flex: 2,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<String>) => (
      <PlatformLabel platform={params.value} />
    ),
  },
  {
    field: 'totalComments',
    headerName: 'Comments'.toLocaleUpperCase(),
    sortable: false,
    flex: 2,
    disableColumnMenu: true,
    valueFormatter: params => formatRowDisplay(params.id, params.value),
  },
  {
    field: 'spamComments',
    headerName: 'Spam'.toLocaleUpperCase(),
    sortable: false,
    flex: 1,
    disableColumnMenu: true,
    valueFormatter: params => formatRowDisplay(params.id, params.value),
  },
  {
    field: 'abusiveComments',
    headerName: 'Abuse'.toLocaleUpperCase(),
    sortable: false,
    flex: 1,
    disableColumnMenu: true,
    valueFormatter: params => formatRowDisplay(params.id, params.value),
  },
  {
    field: 'sentimentScore',
    headerName: 'Postive Sentiment'.toLocaleUpperCase(),
    sortable: false,
    flex: 2,
    disableColumnMenu: true,
  },
]

interface PlatformsTableProps {
  platforms: TrackedPlatformsStatsResponse
}

const createRowsFromPlatformData = (platforms: TrackedPlatformStats[]) => {
  return platforms
    .map(platform => {
      return {
        id: PlatformTypeLabels[platform.id],
        sentimentScore: platform.sentimentScore,
        totalComments: platform.totalComments,
        spamComments: platform.spamComments,
        abusiveComments: platform.abusiveComments,
      }
    })
    .sort((a, b) => a.id.localeCompare(b.id))
}

const calculateTotalsFromRows = (rows: any[]) => {
  return {
    sentimentScore:
      rows.reduce((sum, platform) => sum + platform.sentimentScore, 0) /
      rows.length,
    totalComments: rows.reduce(
      (sum, platform) => sum + platform.totalComments,
      0
    ),
    spamComments: rows.reduce(
      (sum, platform) => sum + platform.spamComments,
      0
    ),
    abusiveComments: rows.reduce(
      (sum, platform) => sum + platform.abusiveComments,
      0
    ),
  }
}

const calculateRelativeDifference = (
  currentTotals: {
    sentimentScore: number
    totalComments: number
    spamComments: number
    abusiveComments: number
  },
  previousTotals: {
    sentimentScore: number
    totalComments: number
    spamComments: number
    abusiveComments: number
  }
) => {
  return {
    sentimentScore: calculatePercentageChange(
      currentTotals.sentimentScore,
      previousTotals.sentimentScore
    ),
    totalComments: calculatePercentageChange(
      currentTotals.totalComments,
      previousTotals.totalComments
    ),
    spamComments: calculatePercentageChange(
      currentTotals.spamComments,
      previousTotals.spamComments
    ),
    abusiveComments: calculatePercentageChange(
      currentTotals.abusiveComments,
      previousTotals.abusiveComments
    ),
  }
}

export const PlatformsTable = (props: PlatformsTableProps) => {
  let currentPlatforms = createRowsFromPlatformData(
    props.platforms.platforms.current
  )
  const previousPlatforms = createRowsFromPlatformData(
    props.platforms.platforms.previous
  )

  const currentTotals = calculateTotalsFromRows(currentPlatforms)
  const previousTotals = calculateTotalsFromRows(previousPlatforms)
  const relativeDifference = calculateRelativeDifference(
    currentTotals,
    previousTotals
  )

  currentPlatforms = currentPlatforms.map(platform => {
    return {
      ...platform,
      sentimentScore: PercentFormat.format(platform.sentimentScore),
    }
  })

  const overallRow = {
    id: 'Overall [+/- change]',
    totalComments: `${CountFormat.format(currentTotals.totalComments)} [${formatDifference(relativeDifference.totalComments)}]`,
    spamComments: `${CountFormat.format(currentTotals.spamComments)} [${formatDifference(relativeDifference.spamComments)}]`,
    abusiveComments: `${CountFormat.format(currentTotals.abusiveComments)} [${formatDifference(relativeDifference.abusiveComments)}]`,
    sentimentScore: `${PercentFormat.format(currentTotals.sentimentScore)} [${formatDifference(relativeDifference.sentimentScore)}]`,
  }

  currentPlatforms.push(overallRow)

  return (
    <DataGrid
      autoHeight
      sx={{
        '& .MuiDataGrid-row:hover': {
          backgroundColor: 'inherit',
        },
        '& .last-row': {
          borderRadius: '5px',
          backgroundColor: '#FFCB00',
          color: '#232132',
          fontWeight: 'bold',
        },
        '& .last-row:hover': {
          backgroundColor: '#FFCB00',
        },
        fontSize: 16,
      }}
      rows={currentPlatforms}
      columns={columns}
      getRowClassName={params => {
        const isLastRow =
          params.indexRelativeToCurrentPage === currentPlatforms.length - 1

        return isLastRow ? 'last-row' : ''
      }}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 100,
          },
        },
      }}
      disableRowSelectionOnClick
      hideFooter={true}
    />
  )
}
