import {
  Middleware,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import logger from 'redux-logger'

import rootReducer from './rootReducer'

const middleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
})

if (process.env.NODE_ENV !== 'production') {
  middleware.push(logger as Middleware)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
  devTools: process.env.NODE_ENV !== 'production',
})

const persister = persistStore(store)

export type AppDispatch = typeof store.dispatch

export { store, persister }
