import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { getPresetDateRange } from 'src/utils/date'
import { DateRange, DatePreset, Filters } from 'src/types'

const initialState = {
  dateRange: getPresetDateRange('last24Hours' as DatePreset),
  trackedAccounts: [],
} as Filters

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setDateRangeFilter(state, action: PayloadAction<DateRange>) {
      state.dateRange = action.payload
    },
    setTrackedAccountsFilter(state, action: PayloadAction<string[]>) {
      state.trackedAccounts = action.payload.map(v => Number(v))
    },
  },
  extraReducers: builder => {
    builder.addCase('user/logout', () => {
      return initialState
    })
  },
})

export const { setDateRangeFilter, setTrackedAccountsFilter } =
  filtersSlice.actions
export default filtersSlice.reducer
