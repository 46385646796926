import { useState, useEffect, useCallback } from 'react'

import {
  Response,
  ActionLogEntry,
  ModerationResolveType,
  AbuseType,
  ActionLogResponse,
} from 'src/types'
import {
  setUnresolvedActionLogEntries,
  setResolvedActionLogEntries,
  setUnresolvedActionLogTotalEntries,
  setResolvedActionLogTotalEntries,
  setUnresolvedActionLogTotalPages,
  setResolvedActionLogTotalPages,
  setActionLogPaginationUpdated,
  resetAllPaginationToPageOne,
} from 'src/redux/reducers'
import { store } from 'src/redux/store'

import client from '../client'

const createParams = (page?: number): any => {
  const actionLogs = store.getState().actionLogs
  const isUnresolved = actionLogs.unresolvedTab
  const currentFilters = store.getState().filters

  const pagination = isUnresolved
    ? actionLogs.unresolvedActionLog.pagination
    : actionLogs.resolvedActionLog.pagination
  const resolvedStatuses = isUnresolved
    ? ['UNRESOLVED']
    : actionLogs.resolvedTypeFilter === 'ALL'
      ? []
      : [actionLogs.resolvedTypeFilter]

  return {
    start: currentFilters.dateRange!.start,
    end: currentFilters.dateRange!.end,
    page: page ?? pagination.currentPage,
    per_page: pagination.perPage,
    sort_by: actionLogs.sortModel.sortBy,
    order: actionLogs.sortModel.sortOrder,
    ...(currentFilters.trackedAccounts &&
    currentFilters.trackedAccounts.length > 0
      ? { tracked_user_ids: currentFilters.trackedAccounts.join(',') }
      : {}),
    ...(resolvedStatuses && resolvedStatuses.length > 0
      ? { resolved_statuses: resolvedStatuses.join(',') }
      : {}),
    ...(actionLogs.abuseTypeFilter !== 'ALL'
      ? { abuse_types: actionLogs.abuseTypeFilter }
      : {}),
    ...(actionLogs.platformFilter !== 'ALL'
      ? { platforms: actionLogs.platformFilter }
      : {}),
  }
}

const parseActionLogEntries = (
  data: Response<ActionLogResponse>
): ActionLogEntry[] => {
  return data.response.entries.map(entry => {
    return {
      ...entry,
      abuseTags: entry.abuseTags.map(tag => AbuseType[tag]),
      resolution: {
        ...entry.resolution,
        status: ModerationResolveType[entry.resolution.status],
      },
    }
  })
}

const parseData = (data: Response<ActionLogResponse>) => {
  const actionLogs = store.getState().actionLogs
  const parsedEntries = parseActionLogEntries(data)

  if (actionLogs.unresolvedTab) {
    store.dispatch(setUnresolvedActionLogEntries(parsedEntries))
    store.dispatch(
      setUnresolvedActionLogTotalEntries(data.response.totalEntries)
    )
    store.dispatch(setUnresolvedActionLogTotalPages(data.response.totalPages))
  } else {
    store.dispatch(setResolvedActionLogEntries(parsedEntries))
    store.dispatch(setResolvedActionLogTotalEntries(data.response.totalEntries))
    store.dispatch(setResolvedActionLogTotalPages(data.response.totalPages))
  }
}

export const useFetchActionLog = (auto: boolean = true) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const actionLogs = store.getState().actionLogs
  const currentFilters = store.getState().filters

  const fetchData = useCallback(async (page?: number) => {
    setLoading(true)

    try {
      const response = await client.get('/moderation/queue', {
        params: createParams(page),
      })
      const data = response.data as Response<ActionLogResponse>
      parseData(data)
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  // fetch data when filters change and reset the currentPage to 1 in both action logs
  useEffect(() => {
    if (auto) {
      store.dispatch(resetAllPaginationToPageOne())
      fetchData(1)
    }
  }, [
    fetchData,
    auto,
    currentFilters,
    actionLogs.platformFilter,
    actionLogs.abuseTypeFilter,
    actionLogs.resolvedTypeFilter,
    actionLogs.sortModel,
  ])

  // fetch data with current filters on tab change
  useEffect(() => {
    if (auto) fetchData()
  }, [fetchData, auto, actionLogs.unresolvedTab])

  // fetch data with current filters on page/size change
  useEffect(() => {
    if (auto && actionLogs.paginationUpdated) {
      fetchData()
      store.dispatch(setActionLogPaginationUpdated(false))
    }
  }, [fetchData, auto, actionLogs.paginationUpdated])

  const refetch = async () => {
    await fetchData()
  }

  return { error, loading, refetch }
}
