import { FunctionComponent } from 'react'
import { Flex, Space } from '@mantine/core'

import { PlatformIcon } from 'src/components'

export interface PlatformUsernameProps {
  platform: string
  username: string
}

export const PlatformUsername: FunctionComponent<
  PlatformUsernameProps
> = props => {
  return (
    <Flex align="center">
      <PlatformIcon fontSize={18} platform={props.platform} />
      <Space w={5} />
      <div>{props.username}</div>
    </Flex>
  )
}
