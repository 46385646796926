import { useEffect, useState } from 'react'
import { Group, Space, Title } from '@mantine/core'
import { useSelector } from 'react-redux'
import { notifications } from '@mantine/notifications'

import { Page, Loading, ActionLogTabs, ActionLogFilters } from 'src/components'
import { RootState } from 'src/redux/reducers'
import { useFetchActionLog, useFetchActionLogTotals } from 'src/api'

export default function ActionLogPage() {
  const actionLogs = useSelector((state: RootState) => state.actionLogs)
  const filters = useSelector((state: RootState) => state.filters)
  const [errorShown, setErrorShown] = useState(false)
  const [initialDataLoaded, setInitialDataLoaded] = useState(true)

  const { loading, error } = useFetchActionLog()
  const { loading: loadingTotals, error: errorTotals } =
    useFetchActionLogTotals()

  useEffect(() => {
    if ((error || errorTotals) && !errorShown) {
      setErrorShown(true)
      notifications.show({
        title: 'Uh-oh! Something Went Wrong',
        message:
          'We were unable to load your data. Please refresh to try again.',
        autoClose: 5000,
      })
    }
  }, [error, errorTotals, errorShown])

  useEffect(() => {
    if (initialDataLoaded && !loading && !loadingTotals) {
      setInitialDataLoaded(false)
    }
  }, [initialDataLoaded, loading, loadingTotals])

  if (initialDataLoaded && loading) return <Loading />

  return (
    <Page noBorder>
      <Group position="apart">
        <Title order={1}>Action Log</Title>
        <ActionLogFilters
          loadingData={loading || loadingTotals}
          filters={filters}
          actionLogs={actionLogs}
        />
      </Group>
      <Space h={32} />
      <ActionLogTabs actionLogs={actionLogs} loadingData={loading} />
    </Page>
  )
}
