import { useState, useEffect, useCallback } from 'react'

import { Response, ActionLogTotalsResponse } from 'src/types'
import { setActionLogTotals } from 'src/redux/reducers'
import { store } from 'src/redux/store'

import client from '../client'

const createParams = (): any => {
  const actionLogs = store.getState().actionLogs
  const currentFilters = store.getState().filters

  return {
    start: currentFilters.dateRange!.start,
    end: currentFilters.dateRange!.end,
    ...(currentFilters.trackedAccounts &&
    currentFilters.trackedAccounts.length > 0
      ? { tracked_user_ids: currentFilters.trackedAccounts.join(',') }
      : {}),
    ...(actionLogs.resolvedTypeFilter !== 'ALL'
      ? { resolved_statuses: actionLogs.resolvedTypeFilter }
      : {}),
    ...(actionLogs.abuseTypeFilter !== 'ALL'
      ? { abuse_types: actionLogs.abuseTypeFilter }
      : {}),
    ...(actionLogs.platformFilter !== 'ALL'
      ? { platforms: actionLogs.platformFilter }
      : {}),
  }
}

export const useFetchActionLogTotals = (auto: boolean = true) => {
  // const [data, setData] = useState<ActionLog | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const actionLogs = store.getState().actionLogs
  const currentFilters = store.getState().filters

  const fetchData = useCallback(async () => {
    setLoading(true)

    try {
      const response = await client.get('/stats/queue/totals', {
        params: createParams(),
      })
      const data = response.data as Response<ActionLogTotalsResponse>

      const totals = {
        unresolved: data.response.totals.totalUnresolved,
        resolved: data.response.totals.totalResolved,
      }

      store.dispatch(setActionLogTotals(totals))
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  // fetch data when filters change
  useEffect(() => {
    if (auto) {
      fetchData()
    }
  }, [
    fetchData,
    auto,
    currentFilters,
    actionLogs.platformFilter,
    actionLogs.abuseTypeFilter,
    actionLogs.resolvedTypeFilter,
  ])

  const refetch = async () => {
    await fetchData()
  }

  return { error, loading, refetch }
}
