export enum AbuseType {
  ABLEIST = 'ABLEIST',
  HOMOPHOBIC = 'HOMOPHOBIC',
  INSULT = 'INSULT',
  MICROAGGRESSION = 'MICROAGGRESSION',
  OFFENSIVE_LANGUAGE = 'OFFENSIVE_LANGUAGE',
  PERSONAL_ATTACK = 'PERSONAL_ATTACK',
  RACIST = 'RACIST',
  SEXIST = 'SEXIST',
  SPAM = 'SPAM',
  THREAT = 'THREAT',
  TRANSPHOBIC = 'TRANSPHOBIC',
  VIOLENT_LANGUAGE = 'VIOLENT_LANGUAGE',
}

export const AbuseTypeLabels: Record<string, string> = {
  ALL: 'All Abuse Types',
  ABLEIST: 'Ableist',
  HOMOPHOBIC: 'Homophobic',
  INSULT: 'Insult',
  MICROAGGRESSION: 'Microaggression',
  OFFENSIVE_LANGUAGE: 'Offensive',
  PERSONAL_ATTACK: 'Personal Attack',
  RACIST: 'Racism',
  SEXIST: 'Sexist',
  SPAM: 'Spam',
  THREAT: 'Threat',
  TRANSPHOBIC: 'Transphobic',
  VIOLENT_LANGUAGE: 'Violent',
}
