import { useState, useCallback, useEffect } from 'react'
import { Response, TrackedUsersStatsResponse, DateRange } from 'src/types'
import { store } from 'src/redux/store'
import { setStats } from 'src/redux/reducers'

import client from '../client'

const createParams = (props: {
  dateRange: DateRange
  trackedAccounts: number[]
}) => {
  return {
    start: props.dateRange.start,
    end: props.dateRange.end,
    ...(props.trackedAccounts && props.trackedAccounts.length > 0
      ? { tracked_user_ids: props.trackedAccounts.join(',') }
      : {}),
  }
}

export const useFetchTrackedUsersStats = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const currentFilters = store.getState().filters

  const fetchData = useCallback(async () => {
    setLoading(true)

    try {
      const response = await client.get('/stats/v2/tracked-users', {
        params: createParams(currentFilters),
      })

      const data = response.data as Response<TrackedUsersStatsResponse>
      store.dispatch(setStats(data.response))
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }, [currentFilters])

  useEffect(() => {
    fetchData()
  }, [fetchData, currentFilters])

  return { error, loading }
}
