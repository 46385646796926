import { useState } from 'react'
import { notifications } from '@mantine/notifications'

import { ModerationActionType, ModerationResolveType } from 'src/types'

import client from '../client'

export const useSendModerationAction = (
  ids: number[],
  action: ModerationActionType,
  resolved_status?: ModerationResolveType
) => {
  // const [data, setData] = useState(false)
  // const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const send = async (): Promise<boolean> => {
    let response
    setLoading(true)

    try {
      response = await client.post('/moderation/queue/action', {
        ids: ids,
        action: action,
        resolved_status: resolved_status,
      })
    } catch (error: any) {
      // setError(error.message)
      notifications.show({
        title: 'Uh-oh! Something Went Wrong',
        message:
          'We were unable to complete this action. Please try again later.',
      })
    } finally {
      setLoading(false)
      return response?.status === 204
    }
  }

  return { loading, send }
}
