import { useNavigate } from 'react-router'

import { Button, Card, Space, Text, Tooltip, Badge } from '@mantine/core'
import { DataGrid } from '@mui/x-data-grid'

import { theme } from 'src/styling/theme'
import { Empty } from 'src/components'
import { CommentAuthor } from 'src/types'

import {
  FAN_FINDER_COLUMNS,
  EXPANDED_FAN_FINDER_COLUMNS,
} from './FanFinderColumns'

interface FanFinderCardProps {
  expanded: boolean
  fans: CommentAuthor[]
  setProfileExpanded?: (expanded: boolean) => void
  setExpandedCommentAuthor?: (commentAuthor: CommentAuthor) => void
}

export const FanFinderCard = (props: FanFinderCardProps) => {
  const navigate = useNavigate()

  const handleRowClick = (params: any) => {
    if (!props.setProfileExpanded || !props.setExpandedCommentAuthor) return
    props.setProfileExpanded(true)
    props.setExpandedCommentAuthor(params.row as CommentAuthor)
  }

  return (
    <Card shadow="xl" radius="lg" p="xl">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text
          size="xl"
          color={theme.colors.dark[2]}
          weight={500}
          style={{ marginRight: '10px' }}
        >
          Fan Finder
        </Text>
        {!props.expanded && (
          <Tooltip
            multiline
            label="The Fan Finder feature displays authentic commenters that have posted multiple very positive comments on your social media accounts, with few negative comments and no abusive or spam comments. Only the top 5 fans are displayed on the Dashboard."
          >
            <Badge color="pink" size="md" radius="xs">
              BETA
            </Badge>
          </Tooltip>
        )}
      </div>

      <Space h="md" />

      {(!props.fans || props.fans.length === 0) && (
        <Empty
          title="No superfans detected!"
          message={
            <>Adjust the timeframe and see if any top fans shine through!</>
          }
        />
      )}

      {props.fans && props.fans.length > 0 && (
        <DataGrid
          autoHeight
          sx={{
            '& .MuiDataGrid-row:hover': {
              backgroundColor: props.expanded ? 'default' : 'inherit',
              cursor: props.expanded ? 'pointer' : 'default',
            },
          }}
          style={{
            border: 0,
            boxShadow: 'none',
            padding: 0,
          }}
          rows={props.fans}
          columns={
            props.expanded ? EXPANDED_FAN_FINDER_COLUMNS : FAN_FINDER_COLUMNS
          }
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          getRowId={row =>
            `${row.platform}-${row.platformId}-${row.trackedUser.id}`
          }
          disableRowSelectionOnClick={props.expanded ? false : true}
          // hideFooter={props.expanded ? false : true}
          hideFooter={true}
          onRowClick={props.expanded ? handleRowClick : () => {}}
        />
      )}
      {!props.expanded && (
        <>
          <Space h="md" />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => navigate('/troll-fan-profiles?tab=fans')}>
              See All Fan Activity
            </Button>
          </div>
        </>
      )}
    </Card>
  )
}
