import { Center, Stack, Image, Text } from '@mantine/core'

import { Page } from 'src/components'

export default function ErrorPage() {
  return (
    <Page title="404: Page Not Found">
      <Center style={{ width: '100%', height: '70vh' }}>
        <Stack
          align="center"
          justify="center"
          spacing={20}
          style={{ width: 400 }}
        >
          <Image src="/flare/lemon-slice.svg" width={128} />
          <Text size={32} align="center">
            Oops!
          </Text>
          <Text size={20} align="center" weight={300}>
            The page you are looking for does not exist.
          </Text>
        </Stack>
      </Center>
    </Page>
  )
}
