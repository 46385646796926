import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import type { AnyAction } from '@reduxjs/toolkit'

import userReducer from './reducers/userReducer'
import dashboardReducer from './reducers/dashboardReducer'
import actionLogsReducer from './reducers/actionLogsReducer'
import filtersReducer from './reducers/filtersReducer'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'], // data persisted on page reload
}

const appReducer = combineReducers({
  user: userReducer,
  filters: filtersReducer,
  dashboard: dashboardReducer,
  actionLogs: actionLogsReducer,
})

export default persistReducer(persistConfig, appReducer)
export type RootState = ReturnType<typeof appReducer>

export const rootReducer = (
  state: RootState | undefined,
  action: AnyAction
) => {
  if (action.type === 'user/logout') {
    storage.removeItem('persist:root')
  }

  return appReducer(state, action)
}
