import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Grid } from '@mantine/core'

import {
  Page,
  AuthLinks,
  MyAccount,
  ModerationSettings,
  TrackedUsers,
  Deactivate,
  UserAccounts,
  Help,
  HowToConnect,
  Loading,
} from 'src/components'
import { OrganizationType, AccessLevel } from 'src/types'
import { RootState } from 'src/redux/rootReducer'
import { useFetchOrganization } from 'src/api'

export default function SettingsPage() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const isAretoAdmin = params.get('user') === 'admin44132'

  const organization = useSelector(
    (state: RootState) => state.user.organization!
  )
  const profile = useSelector((state: RootState) => state.user.profile!)

  const { fetch } = useFetchOrganization()

  useEffect(() => {
    fetch()
  }, [fetch])

  if (!organization) return <Loading />

  return (
    <Page title="Settings">
      <Grid
        style={{
          padding: 0,
        }}
      >
        <Grid.Col
          span={5}
          style={{
            paddingRight: 54,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
          }}
        >
          <AuthLinks />
        </Grid.Col>
        <Grid.Col
          span={7}
          style={{
            padding: 0,
          }}
        >
          <HowToConnect />
        </Grid.Col>
      </Grid>
      {organization.trackedUsers.length > 0 && (
        <TrackedUsers accounts={organization.trackedUsers} />
      )}
      <Grid
        style={{
          padding: 0,
          display: 'flex',
          marginBottom: '54px',
        }}
      >
        <Grid.Col
          span={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingRight: 54,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
          }}
        >
          <MyAccount profile={profile} />
        </Grid.Col>
        <Grid.Col
          span={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
          }}
        >
          <ModerationSettings
            moderateSpam={organization.moderateSpam}
            moderateAbuse={organization.moderateAbuse}
            isCompany={organization.type === OrganizationType.COMPANY}
          />
        </Grid.Col>
      </Grid>

      {organization.type === OrganizationType.COMPANY && (
        <UserAccounts
          isAretoAdmin={isAretoAdmin}
          isAdmin={profile.accessLevel === AccessLevel.ADMIN}
          accounts={organization.accounts}
          myAccountId={profile.id}
        />
      )}

      <Deactivate organizationType={organization.type} />
      <Help />
    </Page>
  )
}
