import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import {
  ActionLogs,
  ActionLogEntry,
  Pagination,
  ActionLogTotals,
  ModerationActionType,
} from 'src/types'

const initialState = {
  abuseTypeFilter: 'ALL',
  platformFilter: 'ALL',
  resolvedTypeFilter: 'ALL',
  unresolvedTab: true,
  sortModel: { sortBy: 'commentPosted', sortOrder: 'desc' },
  unresolvedActionLog: {
    entries: [],
    totalEntries: 0,
    totalPages: 1,
    pagination: {
      currentPage: 1,
      perPage: 10,
    },
    selectedIds: [],
  },
  resolvedActionLog: {
    entries: [],
    totalEntries: 0,
    totalPages: 1,
    pagination: {
      currentPage: 1,
      perPage: 10,
    },
    selectedIds: [],
  },
  totals: {
    unresolved: 0,
    resolved: 0,
  },
  actionModal: {
    open: false,
    action: ModerationActionType.ARCHIVE,
    singleAction: true,
  },
  commentModal: {
    open: false,
  },
  paginationUpdated: false,
} as ActionLogs

const actionLogsSlice = createSlice({
  name: 'actionLogs',
  initialState,
  reducers: {
    setActionLogPlatformFilter(state, action: PayloadAction<string>) {
      state.platformFilter = action.payload
    },
    setActionLogAbuseTypeFilter(state, action: PayloadAction<string>) {
      state.abuseTypeFilter = action.payload
    },
    setActionLogResolvedTypeFilter(state, action: PayloadAction<string>) {
      state.resolvedTypeFilter = action.payload
    },
    setActionLogSortModel(state, action: PayloadAction<any>) {
      state.sortModel = action.payload
    },
    setUnresolvedTab(state, action: PayloadAction<boolean>) {
      state.unresolvedTab = action.payload
    },
    setUnresolvedActionLogEntries(
      state,
      action: PayloadAction<ActionLogEntry[]>
    ) {
      state.unresolvedActionLog.entries = action.payload
    },
    setUnresolvedActionLogTotalEntries(state, action: PayloadAction<number>) {
      state.unresolvedActionLog.totalEntries = action.payload
    },
    setUnresolvedActionLogTotalPages(state, action: PayloadAction<number>) {
      state.unresolvedActionLog.totalPages = action.payload
    },
    setResolvedActionLogEntries(
      state,
      action: PayloadAction<ActionLogEntry[]>
    ) {
      state.resolvedActionLog.entries = action.payload
    },
    setResolvedActionLogTotalEntries(state, action: PayloadAction<number>) {
      state.resolvedActionLog.totalEntries = action.payload
    },
    setResolvedActionLogTotalPages(state, action: PayloadAction<number>) {
      state.resolvedActionLog.totalPages = action.payload
    },
    setUnresolvedActionLogPagination(state, action: PayloadAction<Pagination>) {
      state.unresolvedActionLog.pagination = action.payload
    },
    setResolvedActionLogPagination(state, action: PayloadAction<Pagination>) {
      state.resolvedActionLog.pagination = action.payload
    },
    setUnresolvedActionLogSelectedIds(state, action: PayloadAction<number[]>) {
      state.unresolvedActionLog.selectedIds = action.payload
    },
    setResolvedActionLogSelectedIds(state, action: PayloadAction<number[]>) {
      state.resolvedActionLog.selectedIds = action.payload
    },
    setActionLogTotals(state, action: PayloadAction<ActionLogTotals>) {
      state.totals = action.payload
    },
    setActionLogPaginationUpdated(state, action: PayloadAction<boolean>) {
      state.paginationUpdated = action.payload
    },
    resetAllPaginationToPageOne(state) {
      state.unresolvedActionLog.pagination.currentPage = 1
      state.resolvedActionLog.pagination.currentPage = 1
    },
  },
  extraReducers: builder => {
    builder.addCase('user/logout', () => {
      return initialState
    })
  },
})

export const {
  setActionLogPlatformFilter,
  setActionLogAbuseTypeFilter,
  setActionLogResolvedTypeFilter,
  setActionLogSortModel,
  setUnresolvedTab,
  setUnresolvedActionLogEntries,
  setResolvedActionLogEntries,
  setUnresolvedActionLogTotalEntries,
  setResolvedActionLogTotalEntries,
  setUnresolvedActionLogTotalPages,
  setResolvedActionLogTotalPages,
  setUnresolvedActionLogPagination,
  setResolvedActionLogPagination,
  setUnresolvedActionLogSelectedIds,
  setResolvedActionLogSelectedIds,
  setActionLogPaginationUpdated,
  setActionLogTotals,
  resetAllPaginationToPageOne,
} = actionLogsSlice.actions

export default actionLogsSlice.reducer
