import { useState } from 'react'

import client from '../client'

export const useSendDeactivateAccount = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const send = async (accountId: number): Promise<boolean> => {
    var response
    setLoading(true)

    try {
      response = await client.delete(`/accounts/account/${accountId}`)
    } catch (error: any) {
      console.log(error.response.data.response.error)
      setError(
        error.response.data.response.error || 'Unable to deactivate account.'
      )
    } finally {
      setLoading(false)
      return response?.status === 204
    }
  }

  return { loading, error, send }
}
