import { FunctionComponent, useState } from 'react'
import { createStyles, BoxProps, Menu } from '@mantine/core'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import { Account } from 'src/types'
import { flattenClasses } from 'src/utils/component'
import { logout } from 'src/redux/reducers/userReducer'

const useStyles = createStyles(theme => ({
  profileButton: {
    borderRadius: '100%',
    width: 40,
    height: 40,
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
    fontSize: '20px',
    lineHeight: 1,
    padding: '9px 0px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      color: theme.colors.secondary[0],
    },
  },
  active: {
    color: theme.colors.secondary[0],
  },
}))

export interface AccountButtonProps extends BoxProps {
  profile: Account
}

export const AccountButton: FunctionComponent<AccountButtonProps> = props => {
  const { classes } = useStyles()
  const { profile, ...others } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [opened, setOpened] = useState(false)

  const getInitials = (name: string) => {
    if (!name) {
      return '??'
    }
    const tokens = name.split(' ')
    if (tokens.length < 2) {
      return tokens[0][0]
    }
    return tokens[0][0] + tokens[1][0]
  }

  const initials = getInitials(profile.name)

  const logoutUser = () => {
    dispatch(logout())
    navigate('/login')
  }

  return (
    <Menu
      trigger="hover"
      position="right"
      withArrow
      width={90}
      openDelay={100}
      closeDelay={400}
      opened={opened}
      onChange={setOpened}
    >
      <Menu.Target>
        <div
          className={flattenClasses({
            [classes.profileButton]: true,
            [classes.active]: opened,
          })}
          {...others}
        >
          {initials}
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={() => logoutUser()}>Log out</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
