import { forwardRef } from 'react'
import {
  MultiSelect,
  MultiSelectValueProps,
  SelectItemProps as MantineSelectItemProps,
  CloseButton,
  Flex,
  createStyles,
  Box,
} from '@mantine/core'
import { useSelector } from 'react-redux'

import { PlatformIcon } from 'src/components'
import { RootState } from 'src/redux/rootReducer'

interface SelectItemProps extends MantineSelectItemProps {
  platform: string
}

const useStyles = createStyles(theme => ({
  multiSelectValue: {
    display: 'flex',
    cursor: 'default',
    alignItems: 'center',
    backgroundColor: theme.colors.dark[6],
    border: `1px solid ${theme.colors.dark[4]}`,
    paddingLeft: theme.spacing.xs,
    borderRadius: theme.radius.sm,
    marginRight: theme.spacing.sm,
  },
  itemBox: {
    alignItems: 'center',
    display: 'flex',
    marginRight: theme.spacing.xs,
    fontSize: '18px',
  },
}))

const Value = ({
  value,
  label,
  platform,
  onRemove,
  classNames,
  ...others
}: MultiSelectValueProps & { value: string; platform: string }) => {
  const { classes } = useStyles()

  return (
    <div {...others}>
      <Box className={classes.multiSelectValue}>
        <Box className={classes.itemBox}>
          <PlatformIcon platform={platform} />
        </Box>
        <Box sx={{ lineHeight: 1, fontSize: '12px' }}>{label}</Box>
        <CloseButton
          onMouseDown={onRemove}
          variant="transparent"
          size={22}
          iconSize={14}
          tabIndex={-1}
        />
      </Box>
    </div>
  )
}

const Item = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, value, platform, ...others }, ref) => {
    const { classes } = useStyles()

    return (
      <div ref={ref} {...others}>
        <Flex align="center">
          <Box className={classes.itemBox}>
            <PlatformIcon platform={platform} />
          </Box>
          <div>{label}</div>
        </Flex>
      </div>
    )
  }
)

interface TrackedUserMultiSelectProps {
  onChange: (value: string[]) => void
  initialValues: number[]
}

export const TrackedUserMultiSelect = (props: TrackedUserMultiSelectProps) => {
  const trackedAccounts =
    useSelector((state: RootState) => state.user.organization?.trackedUsers) ||
    []

  return (
    <MultiSelect
      data={trackedAccounts.map(({ id, platformUsername, platform }) => ({
        label: platformUsername,
        value: id.toString(),
        platform: platform,
      }))}
      defaultValue={props.initialValues.map(a => a.toString())}
      onChange={props.onChange}
      valueComponent={Value}
      itemComponent={Item}
      searchable
      placeholder="Accounts"
      nothingFound="No accounts..."
      maxSelectedValues={4}
    />
  )
}
