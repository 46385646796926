import { Stack, Container, Grid, Checkbox, Text, Col } from '@mantine/core'

import { Anchor } from 'src/components'

import { RegisterProps } from '../Register'

export const StepFourConfirm = (props: RegisterProps) => {
  const determineModerationType = () => {
    if (props.values.accountType === 'COMPANY') {
      if (props.values.moderationType === 'AUTOMODERATE') {
        return 'Auto-moderate spam & abusive comments'
      } else if (props.values.moderationType === 'SPAM') {
        return 'Auto-moderate spam only'
      } else if (props.values.moderationType === 'ABUSE') {
        return 'Auto-moderate abusive comments only'
      }
      return 'Track my accounts only (no automoderation)'
    } else {
      if (props.values.moderationType === 'ABUSE') {
        return 'Auto-moderate abusive comments'
      }
      return 'Track my accounts only (no automoderation)'
    }
  }

  return (
    <Stack spacing="sm">
      <Text weight={700} size={18} ml={8}>
        Confirm Your Details:
      </Text>
      <Grid gutter="sm">
        <Col span={5}>
          <Text>Name:</Text>
        </Col>
        <Col span={7}>
          <Text>{props.values.name}</Text>
        </Col>
        <Col span={5}>
          <Text>Email:</Text>
        </Col>
        <Col span={7}>
          <Text>{props.values.email}</Text>
        </Col>
        <Col span={5}>
          <Text>Account Type:</Text>
        </Col>
        <Col span={7}>
          <Text>
            {props.values.accountType === 'COMPANY'
              ? 'Brand account(s)'
              : 'Individual influencer/athlete/public figure account(s)'}
          </Text>
        </Col>
        {props.values.accountType === 'COMPANY' && (
          <>
            <Col span={5}>
              <Text>Organization Name:</Text>
            </Col>
            <Col span={7}>
              <Text>{props.values.organizationName}</Text>
            </Col>
          </>
        )}
        <Col span={5}>
          <Text>Moderation Type:</Text>
        </Col>
        <Col span={7}>
          <Text>{determineModerationType()}</Text>
        </Col>
      </Grid>
      <Container p="sm" ml={0}>
        <Grid grow={false} justify="flex-start">
          <Checkbox
            size="sm"
            label="I agree to the"
            required
            checked={props.values.confirm}
            onChange={e => props.handleSetValue('confirm', e.target.checked)}
          />
          <Anchor
            to="https://www.aretolabs.com/terms"
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: 'none' }}
          >
            <Text
              size="sm"
              weight="bold"
              color="blue"
              style={{ lineHeight: 1.6 }}
            >
              &nbsp;Terms and Conditions
            </Text>
          </Anchor>
        </Grid>
      </Container>
    </Stack>
  )
}
