import { useEffect } from 'react'
import { useLocation, Routes, Route, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { AppShell } from '@mantine/core'
import ReactGA from 'react-ga4'

import AuthPage from './components/Pages/AuthPage'
import Login from './components/Templates/Auth/Login'
import Reset from './components/Templates/Auth/Reset'
import Register from './components/Templates/Auth/Register'
import DashboardPage from './components/Pages/DashboardPage'
import ErrorPage from './components/Pages/ErrorPage'
import ActionLogPage from './components/Pages/ActionLogPage'
import SettingsPage from './components/Pages/SettingsPage'
import AccountDeactivatedPage from './components/Pages/AccountDeactivatedPage'
import TrollFanPage from './components/Pages/TrollFanPage'

import { NavBar } from './components/Organism/NavBar'
import { RootState } from 'src/redux/rootReducer'

import './assets/styles/global.scss'

ReactGA.initialize(process.env.REACT_APP_GTAG!, {
  testMode: process.env.REACT_APP_ENV_NAME !== 'production',
})

export function App() {
  const location = useLocation()
  const navigate = useNavigate()

  const isLoggedIn =
    useSelector((state: RootState) => state.user.token) !== undefined
  const isNavbarHidden =
    ['/login', '/reset', '/register'].includes(location.pathname) || !isLoggedIn

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: location.pathname,
    })
  }, [location])

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login')
    }
  }, [isLoggedIn, navigate])

  return (
    <AppShell padding={0} navbar={isNavbarHidden ? undefined : <NavBar />}>
      <Routes>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/" element={<AuthPage />}>
          <Route path="login" element={<Login />} />
          <Route path="reset" element={<Reset />} />
          <Route path="register" element={<Register />} />
        </Route>
        <Route path="/action-log" element={<ActionLogPage />} />
        <Route path="/troll-fan-profiles" element={<TrollFanPage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route
          path="/account-deactivated"
          element={<AccountDeactivatedPage />}
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </AppShell>
  )
}

export default App
