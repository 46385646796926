export enum LanguageType {
  CZECH = 'cs',
  ENGLISH = 'en',
  FINNISH = 'fi',
  FRENCH = 'fr',
  GERMAN = 'de',
  ITALIAN = 'it',
  SPANISH = 'es',
  SWEDISH = 'sv',
}

export const LanguageTypeLabels: Record<string, string> = {
  cs: 'Czech',
  en: 'English',
  fi: 'Finnish',
  fr: 'French',
  de: 'German',
  it: 'Italian',
  es: 'Spanish',
  sv: 'Swedish',
}
