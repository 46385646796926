import { useState } from 'react'

import client from '../client'

export const useSendDeactivateOrganization = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const send = async (): Promise<boolean> => {
    let response
    setLoading(true)

    try {
      response = await client.delete('/accounts/organization')
    } catch (error: any) {
      setError(error.response.data.response.error)
    } finally {
      setLoading(false)
      return response?.status === 204
    }
  }

  return { loading, error, send }
}
