import { Grid } from '@mantine/core'

import { WelcomeCard } from './WelcomeCard'

export const WelcomeBanner = () => {
  return (
    <section className="page-section">
      <Grid>
        <Grid.Col span={6}>
          <WelcomeCard
            title="Getting Started"
            text="Ready to turn spam, illegal streams and abuse into brand-building lemonade? Connect your social media accounts to get started."
            buttonText="Connect My Accounts"
            buttonLink="/settings"
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <WelcomeCard
            title="Support"
            text="Have any questions about Areto or getting started? Feel free to reach out to us using the link below."
            buttonText="Contact Areto"
            buttonLink={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
          />
        </Grid.Col>
      </Grid>
    </section>
  )
}
