import {
  PasswordInput as MantinePasswordInput,
  PasswordInputProps,
} from '@mantine/core'
import { forwardRef } from 'react'

export const PasswordInput = forwardRef(
  (props: PasswordInputProps, ref: any) => {
    return (
      <MantinePasswordInput
        {...props}
        ref={ref}
        // wrapping labels in span tag so style overrides can target text nodes
        // See ./overrides.ts
        label={<span>{props.label}</span>}
      />
    )
  }
)
