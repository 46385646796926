import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import {
  ModerationPreview,
  AllAccountStats,
  TrackedUsersStatsResponse,
  TrackedPlatformsStatsResponse,
} from 'src/types'

export interface DashboardState {
  aretoScoreFilter: string
  moderationPreview: ModerationPreview | undefined
  accountStats: AllAccountStats | undefined
  trackedAccountStats: TrackedUsersStatsResponse | undefined
  trackedPlatformStats: TrackedPlatformsStatsResponse | undefined
}

const initialState = {
  aretoScoreFilter: 'ALL',
  moderationPreview: undefined,
  accountStats: undefined,
  trackedAccountStats: undefined,
  trackedPlatformStats: undefined,
} as DashboardState

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setAretoScoreFilter(state, action: PayloadAction<string>) {
      state.aretoScoreFilter = action.payload
    },
    setModerationPreview(state, action: PayloadAction<ModerationPreview>) {
      state.moderationPreview = action.payload
    },
    setAccountStats(state, action: PayloadAction<AllAccountStats>) {
      state.accountStats = action.payload
    },
    setStats(state, action: PayloadAction<TrackedUsersStatsResponse>) {
      state.trackedAccountStats = action.payload
    },
    setPlatformStats(
      state,
      action: PayloadAction<TrackedPlatformsStatsResponse>
    ) {
      state.trackedPlatformStats = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase('user/logout', () => {
      return initialState
    })
  },
})

export const {
  setAretoScoreFilter,
  setModerationPreview,
  setAccountStats,
  setStats,
  setPlatformStats,
} = dashboardSlice.actions
export default dashboardSlice.reducer
