import ReactDOM from 'react-dom/client'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'

import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { ModalsProvider } from '@mantine/modals'
import { ThemeProvider } from '@mui/material/styles'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { theme } from './styling/theme'
import { MuiTheme } from './styling/muiTheme'

import { store, persister } from './redux/store'

import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <PersistGate persistor={persister}>
      <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
        <ThemeProvider theme={MuiTheme}>
          <ModalsProvider>
            <BrowserRouter>
              <Notifications position="bottom-right" autoClose={4000} />
              <App />
            </BrowserRouter>
          </ModalsProvider>
        </ThemeProvider>
      </MantineProvider>
    </PersistGate>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
