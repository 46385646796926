import { Title, Badge, Text, Button } from '@mantine/core'
import { modals } from '@mantine/modals'
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  GridRowParams,
  GridActionsCellItem,
} from '@mui/x-data-grid'
import { Icon } from '@iconify/react'

import { TrackedUser, PlatformTypeLabels, PlatformType } from 'src/types'
import { PlatformLabel } from 'src/components'
import { parseToLocalDate } from 'src/utils/date'
import { useSendDisconnectTrackedUser } from 'src/api'

interface TrackedUsersProps {
  accounts: TrackedUser[]
}

export const TrackedUsers = ({ accounts }: TrackedUsersProps) => {
  const { send } = useSendDisconnectTrackedUser()

  const openDisconnectModal = (trackedUserId: number) =>
    modals.openConfirmModal({
      title: 'Disconnect Social Media Account',
      centered: true,
      children: (
        <>
          <Text size="sm" mb={10}>
            Are you sure you want to disconnect this social media account from
            Areto? You will need to reauthorize this account if you change your
            mind.
          </Text>
          <Text size="sm">
            NOTE: If disconnecting a Facebook account, it will also disconnect
            the linked Instagram account.
          </Text>
        </>
      ),
      labels: { confirm: 'Disconnect', cancel: 'Cancel' },
      confirmProps: { variant: 'filled' },
      cancelProps: { color: 'pink', variant: 'subtle' },
      onConfirm: () => send(trackedUserId),
    })

  const openDisconnectInstagramModal = () =>
    modals.open({
      title: 'Disconnect Instagram Account',
      centered: true,
      children: (
        <>
          <Text mb={16} size="sm">
            To disconnect this Instagram account from Areto you will need to
            disconnect the Facebook account that is linked to the Instagram
            account.
          </Text>
          <Button fullWidth onClick={() => modals.closeAll()}>
            Ok
          </Button>
        </>
      ),
    })

  const openConnectModal = () =>
    modals.open({
      title: 'Connect Social Media Account',
      centered: true,
      children: (
        <>
          <Text mb={16} size="sm">
            To connect this social media account again, use the Connect buttons
            above. Be sure to use the correct button for the social media
            account's platform.
          </Text>
          <Button fullWidth onClick={() => modals.closeAll()}>
            Ok
          </Button>
        </>
      ),
    })

  const columns: GridColDef[] = [
    {
      field: 'platformUsername',
      headerName: 'Account Name'.toLocaleUpperCase(),
      flex: 4,
      disableColumnMenu: true,
    },
    {
      field: 'platform',
      headerName: 'Platform'.toLocaleUpperCase(),
      flex: 4,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<String>) => (
        <PlatformLabel platform={PlatformTypeLabels[params.value]} />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Added'.toLocaleUpperCase(),
      flex: 4,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        parseToLocalDate(params.value),
    },
    {
      field: 'isActive',
      headerName: 'Status'.toLocaleUpperCase(),
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<String>) => (
        <Badge
          color={params.value ? 'teal' : 'red'}
          variant="light"
          radius="sm"
          size="lg"
        >
          {params.value ? 'Connected' : 'Disconnected'}
        </Badge>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 60,
      getActions: (params: GridRowParams) => [
        params.row.isActive ? (
          <GridActionsCellItem
            icon={<Icon icon="mdi:close-circle" />}
            onClick={() =>
              params.row.platform !== PlatformType.INSTAGRAM
                ? openDisconnectModal(params.row.id)
                : openDisconnectInstagramModal()
            }
            label="Disconnect"
            showInMenu
          />
        ) : (
          <GridActionsCellItem
            icon={<Icon icon="mdi:connection" />}
            onClick={openConnectModal}
            label="Connect"
            showInMenu
          />
        ),
      ],
    },
  ]

  return (
    <section className="page-section">
      <header>
        <Title order={2}>Social Media Accounts</Title>
      </header>
      <DataGrid
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'inherit',
          },
          fontSize: 16,
          minHeight: '418px',
          padding: '24px',
        }}
        rows={accounts}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </section>
  )
}
