import { Tabs, createStyles } from '@mantine/core'
import { useDispatch } from 'react-redux'

import { ActionLogTable } from './ActionLogTable'
import { setUnresolvedTab } from 'src/redux/reducers'
import { ActionLogs } from 'src/types'

const useStyles = createStyles(theme => ({
  navContainer: {
    position: 'relative',
    marginBottom: theme.spacing.lg,
  },
}))

interface ActionLogTabsProps {
  actionLogs: ActionLogs
  loadingData: boolean
}

export const ActionLogTabs: React.FC<ActionLogTabsProps> = ({
  actionLogs,
  loadingData,
}) => {
  const { classes } = useStyles()
  const dispatch = useDispatch()

  return (
    <Tabs
      onTabChange={(value: string) =>
        dispatch(setUnresolvedTab(value === 'unresolved' ? true : false))
      }
      value={actionLogs.unresolvedTab ? 'unresolved' : 'resolved'}
    >
      <div className={classes.navContainer}>
        <Tabs.List>
          <Tabs.Tab key="unresolved-tab" value="unresolved">
            To Action ({actionLogs.totals.unresolved})
          </Tabs.Tab>
          <Tabs.Tab key="resolved-tab" value="resolved">
            Moderated ({actionLogs.totals.resolved})
          </Tabs.Tab>
        </Tabs.List>
      </div>

      <Tabs.Panel value="unresolved">
        <ActionLogTable
          isUnresolved={true}
          actionLog={actionLogs.unresolvedActionLog}
          loading={loadingData}
        />
      </Tabs.Panel>
      <Tabs.Panel value="resolved">
        <ActionLogTable
          isUnresolved={false}
          actionLog={actionLogs.resolvedActionLog}
          loading={loadingData}
        />
      </Tabs.Panel>
    </Tabs>
  )
}
