/***
 * @description: Function to create a social media profile link. Only supports Instagram, YouTube, and TikTok.
 * The username is the username of the commenter.
 * @param: platform: string, username: string
 * @return: string
 * @example: createCommenterProfileLink('INSTAGRAM', 'username')
 */
export function createCommenterProfileLink(
  platform: string,
  username: string
): string {
  let platformURL = ''

  switch (platform) {
    case 'INSTAGRAM':
      platformURL = `https://www.instagram.com/`
      break
    case 'YOUTUBE':
      platformURL = `https://www.youtube.com/`
      break
    case 'TIKTOK':
      platformURL = `https://www.tiktok.com/@`
      break
    default:
      break
  }

  if (platformURL !== '') {
    platformURL += `${username}`
  }

  return platformURL
}
