// export const LOCALE = 'en-US'

export const SOCIAL_PLATFORM_ICON: Record<string, string> = {
  facebook: 'carbon:logo-facebook',
  instagram: 'carbon:logo-instagram',
  tiktok: 'ic:baseline-tiktok',
  twitter: 'carbon:logo-twitter',
  youtube: 'carbon:logo-youtube',
}

export const SOCIAL_PLATFORMS: string[] = [
  'FACEBOOK',
  'INSTAGRAM',
  'TIKTOK',
  'YOUTUBE',
]

export const CountFormat = new Intl.NumberFormat(process.env.REACT_APP_LOCALE)

export const PercentFormat = new Intl.NumberFormat(
  process.env.REACT_APP_LOCALE,
  {
    style: 'percent',
    minimumFractionDigits: 0,
  }
)

export const DateFormat = new Intl.DateTimeFormat(
  process.env.REACT_APP_LOCALE,
  {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
)

export const LanguageFormat = new Intl.DisplayNames(['en'], {
  type: 'language',
})

export const BRAND_MODERATION_THRESHOLDS = {
  offensiveLanguageAutoModerateThreshold: 4,
  offensiveLanguageModerationQueueThreshold: 4,
  personalAttackAutoModerateThreshold: 2,
  personalAttackModerationQueueThreshold: 2,
  violentLanguageAutoModerateThreshold: 2,
  violentLanguageModerationQueueThreshold: 2,
  threatAutoModerateThreshold: 2,
  threatModerationQueueThreshold: 2,
}

export const INDIVIDUAL_MODERATION_THRESHOLDS = {
  offensiveLanguageAutoModerateThreshold: 2,
  offensiveLanguageModerationQueueThreshold: 2,
  personalAttackAutoModerateThreshold: 2,
  personalAttackModerationQueueThreshold: 2,
  violentLanguageAutoModerateThreshold: 2,
  violentLanguageModerationQueueThreshold: 2,
  threatAutoModerateThreshold: 1,
  threatModerationQueueThreshold: 1,
}
