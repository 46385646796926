import { useState, useCallback } from 'react'

import client from '../client'
import { Response, ActionLogEntry, Filters, CommentAuthor } from 'src/types'

export const useFetchCommentAuthorComments = () => {
  const [data, setData] = useState<ActionLogEntry[] | null>(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetch = useCallback(
    async (commentAuthor: CommentAuthor | null, filters: Filters) => {
      if (!commentAuthor) return // Return if no comment author is provided

      setLoading(true)

      try {
        const response = await client.get(
          `/platforms/users/comment-authors/comments`,
          {
            params: {
              start: filters.dateRange.start,
              end: filters.dateRange.end,
              ...(filters.trackedAccounts && filters.trackedAccounts.length > 0
                ? { tracked_user_ids: filters.trackedAccounts.join(',') }
                : {}),
              comment_author_platform_id: commentAuthor.platformId,
              tracked_user_id: commentAuthor.trackedUser.id,
            },
          }
        )

        const data = response.data as Response<ActionLogEntry[]>
        setData(data.response)

        return data.response
      } catch (error: any) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const reset = useCallback(() => {
    setData(null)
  }, [setData])

  return { error, loading, data, reset, fetch }
}
