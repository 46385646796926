import { Button, Group, Space, Stack, Text } from '@mantine/core'
import { useEffect } from 'react'

import { RegisterOrganizationRequestData } from 'src/types'
import { useSendRegisterOrganization } from 'src/api'

interface StepProps {
  isAdmin: boolean
  activeStep: number
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
  setValidateData: React.Dispatch<React.SetStateAction<boolean>>
  dataValidated: boolean
  setDataValidated: React.Dispatch<React.SetStateAction<boolean>>
  values: RegisterOrganizationRequestData
}

export const StepButtons = (props: StepProps) => {
  const maxStep = props.isAdmin ? 4 : 3
  const isConfirmStep =
    (props.activeStep === 3 && props.isAdmin) ||
    (props.activeStep === 2 && !props.isAdmin)

  const { loading, error, send } = useSendRegisterOrganization()

  const nextStep = () => props.setValidateData(true)

  const prevStep = () =>
    props.setActiveStep(current => (current > 0 ? current - 1 : current))

  useEffect(() => {
    if (props.dataValidated) {
      props.setDataValidated(false)
      props.setActiveStep(current =>
        current < maxStep ? current + 1 : current
      )
    }
  })

  const register = async () => {
    const result = await send(props.values)

    if (result) {
      props.setActiveStep(maxStep)
    }
  }

  if (props.activeStep >= maxStep) return <Space h={48} />

  return (
    <Stack>
      <Group position="center" mt="md">
        <Button
          variant="default"
          onClick={prevStep}
          disabled={props.activeStep === 0}
        >
          Back
        </Button>
        <Button
          onClick={isConfirmStep ? register : nextStep}
          disabled={props.values.confirm === false && isConfirmStep}
          loading={loading}
        >
          {isConfirmStep ? 'Register' : 'Next'}
        </Button>
      </Group>
      {error && (
        <Text
          style={{
            minHeight: '24px',
            color: '#FF6738',
            textAlign: 'center',
          }}
        >
          {error}
        </Text>
      )}
    </Stack>
  )
}
