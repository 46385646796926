import { FunctionComponent, useEffect, useRef } from 'react'
import { Chart as ChartJS, ChartConfiguration, registerables } from 'chart.js'
import { useMantineTheme } from '@mantine/core'

ChartJS.register(...registerables)

export interface ChartProps {
  width: string | number
  height: string | number
  options: ChartConfiguration
}

export const Chart: FunctionComponent<ChartProps> = props => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const theme = useMantineTheme()

  useEffect(() => {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d')
      if (!context) throw Error('No context found.')

      ChartJS.defaults.color = theme.colors.dark[1]
      ChartJS.defaults.backgroundColor = 'transparent'
      ChartJS.defaults.borderColor = 'transparent'
      ChartJS.defaults.elements.point.radius = 4
      // ChartJS.defaults.elements.point.backgroundColor =
      //   theme.colors.secondary[5]
      ChartJS.defaults.elements.point.borderWidth = 0
      ChartJS.defaults.elements.line.tension = 0.5
      // ChartJS.defaults.elements.line.borderColor = theme.colors.secondary[5]
      ChartJS.defaults.elements.line.borderWidth = 2

      const gradient = context.createLinearGradient(0, 0, 0, 512)
      gradient.addColorStop(0, 'rgba(255, 203, 0, 0.07)')
      gradient.addColorStop(1, 'rgba(0, 0, 0, 0)')

      if (props.options.type === 'line') {
        props.options.data.datasets.forEach(d => {
          d.backgroundColor = gradient
          // @ts-ignore
          // d.pointBackgroundColor = theme.colors.secondary[5]
          // @ts-ignore
          d.fill = true
        })
      }
      const chart = new ChartJS(context, props.options)
      return () => chart.destroy()
    }
  }, [props.options, theme.colors.dark, theme.colors.secondary])
  return (
    <div
      style={{
        position: 'relative',
        margin: 'auto',
        height: props.height,
        width: props.width,
      }}
    >
      <canvas ref={canvasRef} />
    </div>
  )
}
