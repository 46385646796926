import { Group, Select, Image } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'

import { SOCIAL_PLATFORMS } from 'src/utils/constants'
import { ComboDatePicker, TrackedUserMultiSelect } from 'src/components'
import { PlatformTypeLabels, Filters, ActionLogs } from 'src/types'
import {
  RootState,
  setDateRangeFilter,
  setActionLogPlatformFilter,
  setTrackedAccountsFilter,
} from 'src/redux/reducers'

export interface ActionLogFiltersProps {
  loadingData: boolean
  filters: Filters
  actionLogs: ActionLogs
}

export const ActionLogFilters = (props: ActionLogFiltersProps) => {
  const dispatch = useDispatch()

  const trackedPlatforms = useSelector(
    (state: RootState) => state.user.trackedPlatforms
  )

  const TRACKED_PLATFORMS =
    trackedPlatforms && trackedPlatforms.length > 0
      ? trackedPlatforms
      : SOCIAL_PLATFORMS

  return (
    <Group>
      {props.loadingData ? (
        <Image
          src="/flare/making-lemonade.gif"
          alt="Empty cup of lemonade"
          width={48}
        />
      ) : (
        <div></div>
      )}
      <TrackedUserMultiSelect
        onChange={v => dispatch(setTrackedAccountsFilter(v))}
        initialValues={props.filters.trackedAccounts}
      />
      <Select
        data={Object.entries(PlatformTypeLabels)
          .filter(([key, value]) => {
            if (TRACKED_PLATFORMS.includes(key) || key === 'ALL') return true
            return false
          })
          .map(([key, value]) => ({
            label: value,
            value: key,
          }))}
        value={props.actionLogs.platformFilter}
        onChange={v => dispatch(setActionLogPlatformFilter(v!))}
      />
      <ComboDatePicker
        selectedDate={props.filters.dateRange}
        stateReducer={setDateRangeFilter}
      />
    </Group>
  )
}
