import { Title } from '@mantine/core'

interface StepProps {
  activeStep: number
  isAdmin: boolean
  isClient: boolean
}

export const StepTitle = (props: StepProps) => {
  if (
    (props.activeStep >= 3 && !props.isAdmin) ||
    (props.activeStep >= 4 && props.isAdmin)
  ) {
    return (
      <Title order={1} mb="lg" style={{ color: '#FF80B7' }}>
        Success!
      </Title>
    )
  } else {
    return (
      <Title order={1} mb="lg">
        {props.isClient ? 'Register for Areto' : 'Register for Free Trial'}
      </Title>
    )
  }
}
