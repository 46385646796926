import { HistogramInterval, PlatformStats } from 'src/types'

/**
 * Calculates the intervals between the start and end dates by day.
 *
 * @param start the start date as an ISOString
 * @param end the end date as an ISOString
 * @returns list of Dates
 */
const _calculateDayIntervals = (start: Date, end: Date): Date[] => {
  let currentDate = new Date(start)
  start.setUTCHours(0, 0, 0, 0)
  let intervals: Date[] = [start]

  while (currentDate < end) {
    currentDate.setDate(currentDate.getDate() + 1)
    let finalDate = new Date(currentDate)
    finalDate.setUTCHours(0, 0, 0, 0)
    intervals.push(finalDate)
  }

  return intervals
}

/**
 * Calculates the intervals between the start and end dates by hour.
 *
 * @param start the start date as an ISOString
 * @param end the end date as an ISOString
 * @returns list of Dates
 */
const _calculateHourIntervals = (start: Date, end: Date): Date[] => {
  let currentDate = new Date(start)
  start.setMinutes(0, 0, 0)
  let intervals: Date[] = [start]

  while (currentDate < end) {
    currentDate.setHours(currentDate.getHours() + 1)
    let finalDate = new Date(currentDate)
    finalDate.setMinutes(0, 0, 0)
    intervals.push(finalDate)
  }

  return intervals
}

/**
 * Calculates the intervals between the start and end dates by week.
 *
 * @param start the start date as an ISOString
 * @param end the end date as an ISOString
 * @returns list of Dates
 */
const _calculateWeekIntervals = (start: Date, end: Date): Date[] => {
  let currentDate = new Date(start)
  start.setUTCHours(0, 0, 0, 0)
  let intervals: Date[] = [start]

  while (currentDate < end) {
    currentDate.setDate(currentDate.getDate() + 7)
    let finalDate = new Date(currentDate)
    finalDate.setUTCHours(0, 0, 0, 0)
    if (finalDate <= end) {
      intervals.push(finalDate)
    }
  }

  return intervals
}

/**
 * Calculates the intervals between the start and end dates by month.
 *
 * @param start the start date as an ISOString
 * @param end the end date as an ISOString
 * @returns list of Dates
 */
const _calculateMonthIntervals = (start: Date, end: Date): Date[] => {
  let currentDate = new Date(start)
  start.setUTCHours(0, 0, 0, 0)
  let intervals: Date[] = [start]

  while (currentDate < end) {
    currentDate.setMonth(currentDate.getMonth() + 1)
    let finalDate = new Date(currentDate)
    finalDate.setUTCHours(0, 0, 0, 0)
    if (finalDate <= end) {
      intervals.push(finalDate)
    }
  }

  return intervals
}

/**
 * Calculates the intervals between the start and end dates by interval.
 *
 * @param start the start date as an ISOString
 * @param end the end date as an ISOString
 * @param interval the type of interval (hour, day)
 * @returns list of Dates
 */
const _calculateIntervals = (
  start: Date,
  end: Date,
  interval: HistogramInterval
): Date[] => {
  switch (interval) {
    case HistogramInterval.Day:
      return _calculateDayIntervals(start, end)
    case HistogramInterval.Week:
      return _calculateWeekIntervals(start, end)
    case HistogramInterval.Month:
      return _calculateMonthIntervals(start, end)
    default:
      return _calculateHourIntervals(start, end)
  }
}

/**
 * Creates default values for a specific platform.
 *
 * All Areto Scores are set to 1, and the comment totals are 0. Only the
 * current account stats will be returned with intervals, that are also
 * set to the defaults.
 *
 * @param platform the platform to create the stats for
 * @param start the start date as an ISOString
 * @param end the end date as an ISOString
 * @param interval the type of interval (hour, day)
 * @param is_current whether this is the current account stats
 * @returns platform stats with default values
 */
export const createEmptyPlatformStats = (
  platform: string,
  start: string,
  end: string,
  interval: HistogramInterval,
  is_current: boolean
): PlatformStats => {
  let intervals: Date[] = []

  if (is_current)
    intervals = _calculateIntervals(new Date(start), new Date(end), interval)

  let stats: PlatformStats = {
    key: platform,
    aretoScore: 1,
    veryPositiveComments: 0,
    positiveComments: 0,
    neutralComments: 0,
    negativeComments: 0,
    veryNegativeComments: 0,
    totalComments: 0,
    intervals: intervals.map(i => {
      return {
        key: i.toISOString(),
        aretoScore: 1,
      }
    }),
  }

  return stats
}
