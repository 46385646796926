import { Title, Group, Button, Badge } from '@mantine/core'
import { modals } from '@mantine/modals'
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
  GridRenderCellParams,
} from '@mui/x-data-grid'
import { Icon } from '@iconify/react'

import { Account, AccessLevelLabels } from 'src/types'
import { parseToLocalDate } from 'src/utils/date'
import { AddUserAccountModal, DeactivateUserAccountModal } from 'src/components'

const openDeactivateModal = (accountId: number, name: string) =>
  modals.open({
    title: 'Deactivate User Account',
    centered: true,
    children: <DeactivateUserAccountModal accountId={accountId} name={name} />,
  })

const openAddModal = () =>
  modals.open({
    title: 'Add User Account',
    centered: true,
    children: <AddUserAccountModal />,
    styles: {
      content: {
        backgroundColor: '#232132 !important',
      },
      header: {
        backgroundColor: '#232132',
      },
      title: {
        backgroundColor: '#232132',
        color: 'white',
      },
      close: {
        svg: {
          color: 'white',
        },
      },
    },
  })

interface UserAccountsProps {
  accounts: Account[]
  isAdmin: boolean
  isAretoAdmin: boolean
  myAccountId: number
}

export const UserAccounts = ({
  accounts,
  isAdmin,
  isAretoAdmin,
  myAccountId,
}: UserAccountsProps) => {
  const createColumns = () => {
    let columns: GridColDef[] = [
      {
        field: 'name',
        headerName: 'Name'.toLocaleUpperCase(),
        flex: 4,
        disableColumnMenu: true,
      },
      {
        field: 'email',
        headerName: 'Email'.toLocaleUpperCase(),
        flex: 4,
        disableColumnMenu: true,
      },
      {
        field: 'createdAt',
        headerName: 'Added'.toLocaleUpperCase(),
        flex: 4,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) =>
          parseToLocalDate(params.value),
      },
      {
        field: 'accessLevel',
        headerName: 'Access'.toLocaleUpperCase(),
        flex: 2,
        disableColumnMenu: true,
        valueGetter(params: GridValueGetterParams) {
          return AccessLevelLabels[params.value]
        },
      },
      {
        field: 'active',
        headerName: 'Status'.toLocaleUpperCase(),
        flex: 2,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<String>) => (
          <Badge
            color={params.value ? 'teal' : 'red'}
            variant="light"
            radius="sm"
            size="lg"
          >
            {params.value ? 'Active' : 'Inactive'}
          </Badge>
        ),
      },
    ]

    if (isAdmin)
      columns.push({
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params: GridRowParams) => {
          // Check if the id of the user in the current row is not equal to myAccountId
          if (params.row.id !== myAccountId && params.row.active) {
            return [
              <GridActionsCellItem
                icon={<Icon icon="mdi:user-off" />}
                onClick={() =>
                  openDeactivateModal(params.row.id, params.row.name)
                }
                label="Deactivate"
                showInMenu
              />,
            ]
          }
          // If the id of the current row is equal to myAccountId, return an empty array
          // Can't deactivate own account in this table
          return []
        },
      })

    return columns
  }

  return (
    <section className="page-section">
      <header>
        <Group>
          <Title order={2}>Organization Accounts</Title>
          <Button onClick={() => openAddModal()}>Add user account</Button>
        </Group>
      </header>
      <DataGrid
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'inherit',
          },
          fontSize: 16,
          padding: '24px',
        }}
        rows={accounts}
        columns={createColumns()}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </section>
  )
}
