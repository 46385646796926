import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid'
import { Icon } from '@iconify/react'

import { PlatformUsername } from 'src/components'
import { createCommenterProfileLink } from 'src/utils/socials'

export const FAN_FINDER_COLUMNS: GridColDef[] = [
  {
    field: 'commentAuthor',
    headerName: 'Commenter'.toLocaleUpperCase(),
    sortable: false,
    flex: 2,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return (
        <PlatformUsername
          platform={params.row.platform}
          username={params.row.platformUsername}
        />
      )
    },
  },
  {
    field: 'trackedUser',
    headerName: 'To'.toLocaleUpperCase(),
    sortable: false,
    flex: 2,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return (
        <PlatformUsername
          platform={params.value.platform}
          username={params.value.platformUsername}
        />
      )
    },
  },
  {
    field: 'totalComments',
    headerName: '# Positive'.toLocaleUpperCase(),
    sortable: false,
    flex: 1.25,
    disableColumnMenu: true,
  },
]

export const EXPANDED_FAN_FINDER_COLUMNS: GridColDef[] = [
  ...FAN_FINDER_COLUMNS.filter(column => column.field !== 'more'), // Remove the existing 'more' column,
  {
    field: 'actions',
    type: 'actions',
    width: 60,
    getActions: (params: GridRowParams) => {
      if (!params.row.platform || !params.row.platformUsername) return []
      if (params.row.platform === 'FACEBOOK') return []

      const profileLink = createCommenterProfileLink(
        params.row.platform,
        params.row.platformUsername
      )

      return [
        <GridActionsCellItem
          icon={<Icon icon="mdi:identification-card-outline" />}
          onClick={() => window.open(profileLink, '_blank')}
          label="View Profile"
          showInMenu
        />,
      ]
    },
  },
]
