import { useState, useEffect } from 'react'

import { Center, Stack, Image, Text, Space } from '@mantine/core'

export const Loading = () => {
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true)
    }, 5000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <Center style={{ width: '100%', height: '80vh' }}>
      <Stack align="center" justify="center" style={{ width: 600 }}>
        <Image
          src="/flare/making-lemonade.gif"
          alt="Empty cup of lemonade"
          width={200}
        />
        {showMessage ? (
          <Text>
            Loading is taking longer than usual. Try reloading the page...
          </Text>
        ) : (
          <Space h={24} />
        )}
      </Stack>
    </Center>
  )
}
