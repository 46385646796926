import { useState, useCallback } from 'react'

import client from '../client'
import { TrollsResponse, Response, CommentAuthor, Filters } from 'src/types'

export const useFetchTrolls = () => {
  const [data, setData] = useState<CommentAuthor[] | null>(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetch = useCallback(async (filters: Filters, limit: number) => {
    setLoading(true)

    try {
      const response = await client.get('/platforms/users/trolls', {
        params: {
          limit: limit,
          start: filters.dateRange.start,
          end: filters.dateRange.end,
          ...(filters.trackedAccounts && filters.trackedAccounts.length > 0
            ? { tracked_user_ids: filters.trackedAccounts.join(',') }
            : {}),
        },
      })
      const data = response.data as Response<TrollsResponse>
      setData(data.response.trolls)
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  return { data, error, loading, fetch }
}
