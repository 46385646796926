import { ReactNode, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Button, Group, Checkbox, Container } from '@mantine/core'
import { modals } from '@mantine/modals'

import {
  ModerationActionType,
  ModerationResolveType,
  ActionLogs,
} from 'src/types'
import {
  setResolvedActionLogSelectedIds,
  setUnresolvedActionLogSelectedIds,
  setUnresolvedActionLogPagination,
  setResolvedActionLogPagination,
  RootState,
} from 'src/redux/reducers'
import {
  useFetchActionLog,
  useSendModerationAction,
  useFetchActionLogTotals,
} from 'src/api'
import { store } from 'src/redux/store'

interface ActionMessageProps {
  buttonText: string
  confirmationMessage: ReactNode
  checkbox?: ReactNode
}

const createArchiveActionMessage = (
  selectedMessage: string
): ActionMessageProps => {
  return {
    buttonText: 'Confirm: Archive',
    confirmationMessage: (
      <p>
        By clicking ‘Confirm: Archive’ below, {selectedMessage} will be sent to
        the Moderated queue with no external action taken.
        <br />
        <br />
        <b>Note:</b> You have 30 days to review {selectedMessage} in the
        Moderated queue.
      </p>
    ),
  }
}

const createDeleteActionMessage = (
  selectedMessage: string
): ActionMessageProps => {
  return {
    buttonText: 'Confirm: Delete',
    confirmationMessage: (
      <p>
        By clicking ‘Confirm: Delete’ below, {selectedMessage} will be deleted
        on the original social media post, and will be sent to the Moderated
        queue. You can review deleted comments in the Moderated queue for 30
        days. This action is ONLY available for Facebook, Instagram and YouTube
        comments.
        <br />
        <br />
        <b>Warning:</b> This action is permanent and cannot be undone!
      </p>
    ),
  }
}

const createHideActionMessage = (
  selectedMessage: string
): ActionMessageProps => {
  return {
    buttonText: 'Confirm: Hide',
    confirmationMessage: (
      <p>
        By clicking ‘Confirm: Hide’ below, {selectedMessage} will be hidden on
        the original social media post.
        <br />
        <br />
        <b>Note:</b> You can review hidden comments in the Moderated queue for
        30 days, and undo hiding, if you are having second thoughts.
      </p>
    ),
  }
}

const createNotAbusiveActionMessage = (
  selectedMessage: string
): ActionMessageProps => {
  return {
    buttonText: 'Confirm: Not Abusive',
    confirmationMessage: (
      <p>
        By clicking ‘Confirm: Not Abusive’ below, {selectedMessage} will be sent
        to the Moderated queue with no external action taken. By marking
        comments as not abusive, you are helping Areto become more accurate at
        singling out truly abusive content.
        <br />
        <br />
        <b>Note:</b> You have 30 days to review {selectedMessage} in the
        Moderated queue.
      </p>
    ),
  }
}

const createUnarchiveActionMessage = (
  selectedMessage: string,
  isSingleAction: boolean
): ActionMessageProps => {
  return {
    buttonText: 'Confirm: Unarchive',
    confirmationMessage: (
      <p>
        By clicking ‘Confirm: Unarchive’ below, {selectedMessage} will be sent
        back to the To Action queue. In the To Action queue, you will be able to
        perform a variety of actions on {selectedMessage}.
        <br />
        <br />
        <b>Note:</b>{' '}
        {isSingleAction
          ? 'You can always archive this comment again, if you do not want to perform actions.'
          : 'You are only able to unarchive comments that currently have a status of Archived.'}
      </p>
    ),
  }
}

const createUndoHideActionMessage = (
  selectedMessage: string,
  setResolvedStatus: (arg0: ModerationResolveType | undefined) => void
): ActionMessageProps => {
  return {
    buttonText: 'Confirm: Undo Hide',
    confirmationMessage: (
      <p>
        By clicking ‘Confirm Undo Hide’ below, {selectedMessage} will be
        unhidden on the original social media post. All of you followers will be
        able to see it again. The status of {selectedMessage} will be updated to
        Not Abusive.
        <br />
        <br />
        <b>Warning:</b> You are only able to undo hidden comments that currently
        have a status of Hidden.
      </p>
    ),
    checkbox: (
      <Container
        style={{
          fontSize: '20px',
          padding: '10px',
          marginBottom: 16,
          backgroundColor: '#463E61',
          borderRadius: 6,
        }}
      >
        <Checkbox
          label={`Move ${selectedMessage} back to the To Action queue`}
          onChange={e => {
            setResolvedStatus(
              e.currentTarget.checked
                ? ModerationResolveType.UNRESOLVED
                : undefined
            )
          }}
        />
      </Container>
    ),
  }
}

const createActionMessage = (
  numSelected: number,
  action: ModerationActionType,
  isSingleAction: boolean,
  setResolvedStatus: (arg0: any) => void
): ActionMessageProps => {
  const selectedMessage =
    numSelected === 1 ? 'this comment' : `the ${numSelected} selected comments`

  switch (action) {
    case ModerationActionType.ARCHIVE:
      return createArchiveActionMessage(selectedMessage)
    case ModerationActionType.DELETE:
      return createDeleteActionMessage(selectedMessage)
    case ModerationActionType.HIDE:
      return createHideActionMessage(selectedMessage)
    case ModerationActionType.FALSE_POSITIVE:
      return createNotAbusiveActionMessage(selectedMessage)
    case ModerationActionType.UNARCHIVE:
      return createUnarchiveActionMessage(selectedMessage, isSingleAction)
    case ModerationActionType.UNDO_HIDE:
      return createUndoHideActionMessage(selectedMessage, setResolvedStatus)
    default:
      return createArchiveActionMessage(selectedMessage)
  }
}

/**
 * Check if the pagination needs to be updated now that items have been deleted.
 *
 * If the action log is currently on the last page, which isn't the first page,
 * and the number of selected entries is equal to the number of entries on the page,
 * then set the current page to the previous page.
 *
 * If the pagination has been changed, do not need to refetch entries/totals, as
 * changing pagination will do that automatically.
 *
 * @param actionLog the current action log state
 * @returns boolean whether the pagination has been changed or not
 */
const checkPagination = (actionLog: ActionLogs): boolean => {
  if (
    actionLog.unresolvedTab &&
    actionLog.unresolvedActionLog.pagination.currentPage !== 1 &&
    actionLog.unresolvedActionLog.selectedIds.length ===
      actionLog.unresolvedActionLog.entries.length &&
    actionLog.unresolvedActionLog.pagination.currentPage ===
      actionLog.unresolvedActionLog.totalPages
  ) {
    store.dispatch(
      setUnresolvedActionLogPagination({
        ...actionLog.unresolvedActionLog.pagination,
        currentPage: actionLog.unresolvedActionLog.pagination.currentPage - 1,
      })
    )
    return true
  }

  if (
    !actionLog.unresolvedTab &&
    actionLog.resolvedActionLog.pagination.currentPage !== 1 &&
    actionLog.resolvedActionLog.selectedIds.length ===
      actionLog.resolvedActionLog.entries.length &&
    actionLog.resolvedActionLog.pagination.currentPage ===
      actionLog.resolvedActionLog.totalPages
  ) {
    store.dispatch(
      setResolvedActionLogPagination({
        ...actionLog.resolvedActionLog.pagination,
        currentPage: actionLog.resolvedActionLog.pagination.currentPage - 1,
      })
    )
    return true
  }

  return false
}

interface ActionModalProps {
  action: ModerationActionType
  singleAction: boolean
  singleId?: number
}

export const ActionModal = ({
  action,
  singleAction,
  singleId,
}: ActionModalProps) => {
  const [resolvedStatus, setResolvedStatus] = useState(undefined)

  const dispatch = useDispatch()
  const actionLogs = useSelector((state: RootState) => state.actionLogs)
  const ids = singleAction
    ? [singleId!]
    : actionLogs.unresolvedTab
      ? actionLogs.unresolvedActionLog.selectedIds
      : actionLogs.resolvedActionLog.selectedIds

  const { buttonText, confirmationMessage, checkbox } = createActionMessage(
    ids.length,
    action,
    singleAction,
    setResolvedStatus
  )

  const clearSelectedIds = useCallback(() => {
    if (actionLogs.unresolvedTab)
      dispatch(setUnresolvedActionLogSelectedIds([]))
    else dispatch(setResolvedActionLogSelectedIds([]))
  }, [actionLogs.unresolvedTab, dispatch])

  const { loading, send } = useSendModerationAction(ids, action, resolvedStatus)
  const { loading: loadingActionLog, refetch } = useFetchActionLog(false)
  const { loading: loadingTotals, refetch: refetchTotals } =
    useFetchActionLogTotals(false)

  const performAction = async () => {
    await send()
    if (!checkPagination(actionLogs)) {
      await refetch()
    }
    await refetchTotals()
    clearSelectedIds()
    modals.closeAll()
  }

  return (
    <>
      {confirmationMessage}
      {checkbox || <div></div>}
      <Group position="right">
        <Button
          variant="subtle"
          disabled={loading || loadingActionLog || loadingTotals}
          onClick={() => modals.closeAll()}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          loading={loading || loadingActionLog || loadingTotals}
          loaderPosition="center"
          onClick={performAction}
        >
          {buttonText}
        </Button>
      </Group>
    </>
  )
}
