import { Link } from 'react-router-dom'

import { Center, Stack, Image, Text, Button } from '@mantine/core'

import { Page, Anchor } from 'src/components'

export default function AccountDeactivatedPage() {
  return (
    <Page title="Account Has Been Deactivated">
      <Center style={{ width: '100%', height: '70vh' }}>
        <Stack
          align="center"
          justify="center"
          spacing={20}
          style={{ width: 400 }}
        >
          <Image src="/flare/lemon-slice.svg" width={128} />
          <Text size={32} align="center">
            We are sorry to see you go!
          </Text>
          <Text size={20} align="center" weight={300}>
            If this was a mistake, please contact Areto support using the link
            below.
          </Text>
          <Button
            component={Link}
            to={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
          >
            Contact Areto Support
          </Button>
          <Anchor to="/">Return to Home</Anchor>
        </Stack>
      </Center>
    </Page>
  )
}
