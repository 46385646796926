export enum ModerationActionType {
  ARCHIVE = 'ARCHIVE',
  // BLOCK = "BLOCK",
  DELETE = 'DELETE',
  HIDE = 'HIDE',
  FALSE_POSITIVE = 'FALSE_POSITIVE',
  // REPLY = "REPLY",
  // REPORT = "REPORT",
  UNARCHIVE = 'UNARCHIVE',
  UNDO_HIDE = 'UNDO_HIDE',
}
