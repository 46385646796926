import { Text } from '@mantine/core'
import {
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { modals } from '@mantine/modals'
import { Icon } from '@iconify/react'

import { CommentModal, AbuseTags, PlatformUsername } from 'src/components'
import { ModerationResolveType, ModerationResolveTypeLabels } from 'src/types'
// import { createCommenterProfileLink } from 'src/utils/socials';
import { parseToLocalDateTime } from 'src/utils/date'

const openCommentModal = (entry: any) => {
  modals.open({
    title: 'Comment Details',
    centered: true,
    children: <CommentModal entry={entry} />,
    size: 'lg',
  })
}

export const COMMENT_COLUMNS: GridColDef[] = [
  {
    field: 'trackedUser',
    headerName: 'To'.toLocaleUpperCase(),
    sortable: false,
    flex: 1.5,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return (
        <PlatformUsername
          platform={params.value.platform}
          username={params.value.platformUsername}
        />
      )
    },
  },
  {
    field: 'comment',
    headerName: 'Comment'.toLocaleUpperCase(),
    sortable: false,
    flex: 4,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return <Text>{params.value.text}</Text>
    },
  },
  {
    field: 'abuseTags',
    headerName: 'Abuse Types'.toLocaleUpperCase(),
    sortable: false,
    flex: 1.5,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return (
        <>
          {params.value !== null && (
            <AbuseTags tags={params.value} numToShow={1} />
          )}
        </>
      )
    },
  },
  {
    field: 'posted',
    headerName: 'Posted'.toLocaleUpperCase(),
    sortable: false,
    flex: 1.5,
    disableColumnMenu: true,
    valueGetter: (params: GridValueGetterParams) =>
      parseToLocalDateTime(params.row.comment.timestamp),
  },
  {
    field: 'resolution',
    headerName: 'Status'.toLocaleUpperCase(),
    sortable: false,
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      const status = params.value?.status as keyof typeof ModerationResolveType
      return (
        <Text>{status ? ModerationResolveTypeLabels[status] : 'Visible'}</Text>
      )
    },
  },
  {
    field: 'actions',
    type: 'actions',
    width: 60,
    getActions: (params: GridRowParams) => {
      let actionItems = [
        <GridActionsCellItem
          icon={<Icon icon="mdi:arrow-expand" />}
          onClick={() => openCommentModal(params.row)}
          label="Expand"
        />,
      ]

      // if (params.row.comment) {
      //   const platformURL = createCommenterProfileLink(
      //     params.row.trackedUser.platform,
      //     params.row.comment.author
      //   );

      //   if (platformURL !== '') {
      //     actionItems.push(
      //       <GridActionsCellItem
      //         icon={<Icon icon="mdi:identification-card-outline" />}
      //         onClick={() => window.open(platformURL)}
      //         label="View Profile"
      //         showInMenu
      //       />
      //     );
      //   }
      // }

      return actionItems
    },
  },
]
