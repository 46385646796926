import { useCallback } from 'react'

import {
  DataGrid,
  GridRowSelectionModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid'
import { useDispatch } from 'react-redux'

import {
  setUnresolvedActionLogPagination,
  setResolvedActionLogPagination,
  setUnresolvedActionLogSelectedIds,
  setResolvedActionLogSelectedIds,
  setActionLogSortModel,
  setActionLogPaginationUpdated,
} from 'src/redux/reducers'

import { ActionLogTableToolbar } from './ActionLogTableToolbar'
import { ActionLogTableEmpty } from './ActionLogTableEmpty'
import { createColumnData } from './ActionLogTableColumns'
import { ActionLog } from 'src/types'

interface ActionLogTableProps {
  isUnresolved: boolean
  actionLog: ActionLog
  loading: boolean
}

enum ActionLogSortBy {
  commentPosted = 'COMMENT_TIMESTAMP',
  commentAuthor = 'COMMENT_AUTHOR',
}

enum ActionLogSortOrder {
  asc = 'ASC',
  desc = 'DESC',
}

export const ActionLogTable = (props: ActionLogTableProps) => {
  const dispatch = useDispatch()

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      const sortBy =
        ActionLogSortBy[sortModel[0].field as keyof typeof ActionLogSortBy]
      const sortOrder =
        ActionLogSortOrder[sortModel[0].sort as keyof typeof ActionLogSortOrder]
      dispatch(
        setActionLogSortModel({
          sortBy: sortBy,
          sortOrder: sortOrder,
        })
      )
    },
    [dispatch]
  )

  const entries = props.actionLog.entries.map(entry => {
    return {
      id: entry.id,
      trackedUser: entry.trackedUser,
      commentAuthor: entry.comment.author,
      comment: entry.comment.text,
      commentLanguage: entry.comment.language,
      commentPosted: entry.comment.timestamp,
      abuseTags: entry.abuseTags,
      resolutionStatus: entry.resolution,
      entry: entry,
    }
  })

  const updatePagination = (model: GridPaginationModel) => {
    // Scroll to the top of the page
    window.scrollTo(0, 0)

    // user changed page on the table
    dispatch(setActionLogPaginationUpdated(true))

    if (props.isUnresolved)
      dispatch(
        setUnresolvedActionLogPagination({
          currentPage: model.page + 1,
          perPage: model.pageSize,
        })
      )
    else
      dispatch(
        setResolvedActionLogPagination({
          currentPage: model.page + 1,
          perPage: model.pageSize,
        })
      )
  }

  return (
    <DataGrid
      sx={{
        '& .MuiDataGrid-row:hover': {
          backgroundColor: 'inherit',
        },
        '--DataGrid-overlayHeight': '300px',
        '.MuiDataGrid-actionsCell': {
          gridGap: 2,
        },
      }}
      autoHeight
      rows={entries}
      columns={createColumnData(props.isUnresolved)}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
        sorting: {
          sortModel: [
            {
              field: 'commentPosted',
              sort: 'desc',
            },
          ],
        },
      }}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      sortingOrder={['desc', 'asc']}
      pageSizeOptions={[10, 25, 50, 100]}
      checkboxSelection
      rowCount={props.actionLog.totalEntries}
      disableColumnMenu
      slots={{
        toolbar: ActionLogTableToolbar,
        noRowsOverlay: ActionLogTableEmpty,
      }}
      onPaginationModelChange={updatePagination}
      onRowSelectionModelChange={(model: GridRowSelectionModel) => {
        props.isUnresolved
          ? dispatch(setUnresolvedActionLogSelectedIds(model as number[]))
          : dispatch(setResolvedActionLogSelectedIds(model as number[]))
      }}
      rowSelectionModel={props.actionLog.selectedIds}
      paginationMode="server"
      paginationModel={{
        pageSize: props.actionLog.pagination.perPage,
        page: props.actionLog.pagination.currentPage - 1,
      }}
      loading={props.loading}
      // keepNonExistentRowsSelected // for keeping ids with pagination -- buggy for filters
    />
  )
}
