import { MantineTheme } from '@mantine/core'
import { components } from './overrides'

const theme = {
  colorScheme: 'dark',
  // Typography
  fontFamily: 'Outfit, sans-serif',
  headings: {
    fontFamily: 'Outfit, sans-serif',
    fontWeight: 500,
    sizes: {
      h1: {
        fontSize: 32,
      },
      h2: {
        fontSize: 24,
      },
    },
  },
  // Colors
  primaryColor: 'primary',
  primaryShade: 5,
  spacing: {
    xs: 8,
    sm: 12,
    md: 16,
    lg: 24,
    xl: 32,
  },
  defaultRadius: 'sm',
  radius: {
    xs: 6,
    sm: 8,
    md: 12,
    lg: 16,
    xl: 24,
  },
  shadows: {
    xl: '0px 3px 10px rgba(0, 0, 0, 0.35)',
  },
  cursorType: 'pointer',
  components,
  colors: {
    light: [
      '#212121', // Modal Background
      '#FBFCFC', // Dropdown Background
      '#D9D5E8', // Dropdown Background Hover
    ],
    dark: [
      '#FBFCFC',
      '#F1EAFF',
      '#D9D5E8',
      '#595669',
      '#463E61',
      '#2F2C43',
      '#292638',
      '#232132',
      '#1F1D2B',
      '#1F1D2B',
    ],
    purple: [
      '#FBFCFC',
      '#D9D5E8',
      '#595669',
      '#595669',
      '#463e61',
      '#2f2c43',
      '#292638',
      '#232132',
      '#1f1d2b',
      '#1f1d2b',
    ],
    primary: [
      '#FCFBFC',
      '#ECDEE5',
      '#E0C0D0',
      '#D9A1BC',
      '#f57bb0',
      '#FF80B7',
      '#EA328C',
      '#D32D7E',
      '#AF3772',
      '#933C67',
      '#7C3E5C',
    ],
    secondary: [
      '#FFCB00',
      '#FFCB00',
      '#FFCB00',
      '#FFCB00',
      '#FFCB00',
      '#FFCB00',
      '#C19A0C',
      '#C19A0C',
      '#C19A0C',
    ],
    tertiary: [
      '#5CE0D9',
      '#5CE0D9',
      '#5CE0D9',
      '#5CE0D9',
      '#5CE0D9',
      '#5CE0D9',
      '#5CE0D9',
      '#5CE0D9',
      '#5CE0D9',
      '#5CE0D9',
    ],
    error: ['#fc430a'],
  },
} as unknown as MantineTheme

const lightTheme = {
  ...theme,
  colorScheme: 'light',
} as MantineTheme

export { theme, lightTheme }
