import { Card } from '@mantine/core'

import { Empty, Anchor } from 'src/components'

export const NoTrackedPlatforms = () => {
  return (
    <Card>
      <Empty
        title="Nothing here...yet"
        message={
          <>
            You aren’t tracking any social media platforms yet. Go to{' '}
            <Anchor to="/settings">Settings</Anchor> to link your accounts and
            get started.
          </>
        }
      />
    </Card>
  )
}
