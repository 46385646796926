export enum PlatformType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  // TWITTER = 'TWITTER',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
}

export const PlatformTypeLabels: Record<string, string> = {
  ALL: 'All  Platforms',
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  // TWITTER: 'Twitter',
  TIKTOK: 'TikTok',
  YOUTUBE: 'YouTube',
}
