import { CountFormat } from './constants'

export function flattenClasses(classes: Record<string, any>): string {
  return Object.entries(classes).reduce((flatClasses, [c, isEnabled]) => {
    if (isEnabled === true) flatClasses += ` ${c}`
    return flatClasses
  }, '')
}

export const formatRowDisplay = (id: any, value: any) => {
  // Convert the value to a number if it's a string
  const number =
    typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value

  // Format number leveraging CountFormat defined in constants.ts
  if (id !== 'Overall [+/- change]' && id !== 1) {
    return CountFormat.format(number)
  }
}

export const formatDifference = (value: number) => {
  const sign = value > 0 ? '+' : value < 0 ? '-' : ''

  // Round to 1 decimal place using toFixed(1)
  return `${sign}${Math.abs(value).toFixed(1)}%`
}
