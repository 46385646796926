import { FunctionComponent } from 'react'

import { Card, Grid, Space, Text } from '@mantine/core'
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid'

import { PlatformIcon, Empty } from 'src/components'
import { ActionLogEntry, CommentAuthor } from 'src/types'

import { COMMENT_COLUMNS } from './CommentAuthorProfileInformationColumns'

interface CommentAuthorProfileInformationCardProps {
  commentAuthor: CommentAuthor | null
  comments: ActionLogEntry[] | null
  profileExpanded: boolean
  pagination: GridPaginationModel
  setPagination: (pagination: GridPaginationModel) => void
  loading: boolean
}

export const CommentAuthorProfileInformationCard: FunctionComponent<
  CommentAuthorProfileInformationCardProps
> = ({
  profileExpanded,
  commentAuthor,
  comments,
  pagination,
  setPagination,
  loading,
}) => {
  return (
    <>
      <Grid.Col span={12}>
        <Card shadow="xl" radius="lg" p="xl">
          {(!profileExpanded ||
            commentAuthor === null ||
            comments === null) && (
            <Empty
              title="No profile selected."
              message="Click on a commenter in the table above to see all their comments and decide whether to take action."
            />
          )}

          {profileExpanded && commentAuthor !== null && comments !== null && (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  <PlatformIcon
                    fontSize={32}
                    platform={commentAuthor.platform}
                    style={{ marginRight: '10px' }}
                  />

                  <div>
                    <Text size="xl" weight={500}>
                      {commentAuthor.platformUsername}
                    </Text>
                  </div>
                </div>
              </div>
              <Space h="md" />
              <DataGrid
                autoHeight
                sx={{
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: 'inherit',
                  },
                }}
                style={{
                  border: 0,
                  boxShadow: 'none',
                  padding: 0,
                }}
                rows={comments}
                columns={COMMENT_COLUMNS}
                paginationModel={pagination}
                onPaginationModelChange={setPagination}
                getRowId={row => `${row.comment.id}-${row.comment.timestamp}`}
                disableRowSelectionOnClick
                loading={loading}
              />
            </div>
          )}
        </Card>
      </Grid.Col>
    </>
  )
}
